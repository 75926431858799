import moment from "moment";
import { isNull } from "lodash";

export const formatDate = function (value) {
  if (value && !isNull(value)) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
};

export const formatDateTime = function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY hh:mm");
  }
};

export const toCurrency = function (value) {
  if (isNaN(value)) {
    return "";
  }

  var formatter = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
};

export const capitalizeFirstLetter = function (value) {
  return (
    value.toLowerCase().charAt(0).toUpperCase() + value.toLowerCase().slice(1)
  );
};

export const last4Character = function (value) {
  if (value) {
    return value.toLowerCase().substr(value.length - 4);
  }
  return "";
};
