<template>
  <component :is="comp" :prodotto="prodotto" :index="index"></component>
</template>

<script>
//Vue 3 is having a special function to define these async functions
import { defineAsyncComponent } from "vue";

export default {
  name: "DynamicComponent",
  //I am passing the name of the Component as a prop
  props: {
    componentName: {
      type: String,
      required: true,
    },
    prodotto: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  watch: {
    prodotto: {
      handler: function () {},
      deep: true,
    },
  },
  computed: {
    comp() {
      return defineAsyncComponent(() =>
        import(`@/components/prodotti/${this.componentName}.vue`)
      );
    },
  },
};
</script>
