import { LOADING_START, LOADING_END } from "../actions/portal";

const state = {
  loading: true,
};

const getters = {
  getLoading: (state) => state.loading,
};

const actions = {
  async loadingstart({ commit }) {
    commit(LOADING_START);
  },
  async loadingend({ commit }) {
    commit(LOADING_END);
  },
};

const mutations = {
  [LOADING_START]: (state) => {
    state.loading = true;
  },
  [LOADING_END]: (state) => {
    state.loading = false;
  },
};

export const portalStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
