<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">
                Beneficiari<br />{{ policy_number }}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div class="col-md-12 pl-md-0">
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>
          <br />
          <card class="card-frame" v-if="legittimi">
            <h4 class="text-uppercase ls-1 text-primary py-3 mb-2">
              Hai scelto di inserire come Beneficiari gli Eredi Legittimi.
            </h4>
          </card>
          <card class="card-frame" v-if="tipologiabenef > 0">
            <h4 class="text-uppercase ls-1 text-primary py-3 mb-2">
              {{ decodTipologiaBenef() }}
            </h4>
          </card>
          <div class="row" v-if="haveBeneficiari">
            <div class="col-md-12 pl-md-0">
              <card
                class="card-blog"
                v-for="beneficiario in beneficiari"
                :key="beneficiario.idrsspolizzabenef"
              >
                <div class="table-responsive">
                  <table class="table table-shopping">
                    <tbody>
                      <tr>
                        <td style="border: 0px">
                          <div class="img-container">
                            <i
                              class="fa fa-male fa-5x"
                              v-if="beneficiario.gender === 'M'"
                            ></i>
                            <i class="fa fa-female fa-5x" v-else></i>
                          </div>
                        </td>
                        <td class="td-name" style="border: 0px">
                          {{ beneficiario.firstname }}
                          {{ beneficiario.lastname }}
                          <br /><small>{{
                            formatDate(beneficiario.birthdate)
                          }}</small>
                        </td>
                        <td class="td-number" style="border: 0px">
                          Titolare al {{ beneficiario.percentuale
                          }}<small>%</small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </card>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { formatDate } from "@/filters/formatDate";
export default {
  name: "beneficiari-page",
  components: {
    Loading,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(false);

    const beneficiari = ref(null);
    const haveBeneficiari = ref(null);
    const tipologiabenef = ref(null);
    const legittimi = ref(false);
    const policy_number = ref(null);

    onMounted(async () => {
      isLoading.value = true;
      policy_number.value = route.params.id;
      let resp = await apiCall({
        url: "/private/polizza/get-beneficiari/" + policy_number.value,
        data: {},
        method: "GET",
      });

      beneficiari.value = resp.beneficiari;
      tipologiabenef.value = resp.polizzaDati.tipologiabenef;
      legittimi.value = resp.polizzaDati.legittimi;
      if (beneficiari.value && beneficiari.value.length > 0) {
        haveBeneficiari.value = true;
      } else {
        haveBeneficiari.value = false;
      }
      isLoading.value = false;
    });

    const goToHome = function () {
      router.push("/");
    };

    const decodTipologiaBenef = function () {
      switch (tipologiabenef.value) {
        case 1:
          return "Coniuge";
        case 2:
          return "Figli in parti uguali";
        case 3:
          return "Coniuge e Figli in parti uguali";
      }
      return "Nessuna scelta";
    };

    return {
      haveBeneficiari,
      beneficiari,
      decodTipologiaBenef,
      legittimi,
      tipologiabenef,
      isLoading,
      policy_number,
      goToHome,
      formatDate,
    };
  },
};
</script>
<style></style>
