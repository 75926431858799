<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">
                Metodo pagamento
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div class="col-md-12 pl-md-0">
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>
          <br />
          <div class="table-responsive" v-if="pagamento">
            <table class="table align-items-center">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Conto Corrente</th>
                  <th scope="col">Attivo</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <i class="fa fa-bank fa-3x"></i>
                  </th>
                  <td>
                    <span class="d-block"
                      >•••• •••• •••• {{ last4Character(pagamento.iban) }}</span
                    >
                    <small class="text-muted"
                      >Data mandato:
                      {{
                        formatDate(pagamento.data_sottoscrizione_mandato)
                      }}</small
                    >
                  </td>
                  <td class="text-center">
                    <i
                      class="fa fa-check fa-2x"
                      style="color: green"
                      aria-hidden="true"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
            <base-button
              type="primary"
              size="sm"
              @click="showModalBanca = true"
            >
              Cambia IBAN
            </base-button>
          </div>
        </card>
      </div>
    </div>
    <modal
      :show="showModalBanca"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-lg"
    >
      <card
        type="secondary"
        shadow
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <div class="text-center text-muted mb-4">AGGIUNGI NUOVO IBAN</div>
        <base-alert type="danger" v-if="!isValidIBAN">
          <strong>IBAN non valido</strong>
        </base-alert>
        <form role="form">
          <base-input
            alternative
            class="mb-3"
            name="iban"
            placeholder="IBAN"
            @input="validateIBAN"
            addon-left-icon="fa fa-bank"
          >
          </base-input>
          <div class="text-center">
            <base-button
              :disabled="!isValidIBAN && iban == ''"
              type="primary"
              class="my-4"
              @click="saveIBAN()"
              >SALVA</base-button
            >
            <base-button
              type="primary"
              class="my-4"
              @click="showModalBanca = false"
              >Chiudi</base-button
            >
          </div>
        </form>
      </card>
    </modal>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { formatDate, last4Character } from "@/filters/formatDate";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import BaseAlert from "@/components/BaseAlert";
import Modal from "@/components/Modal";

export default {
  name: "pagamento-ass-page",
  components: {
    Loading,
    BaseAlert,
    Modal,
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(false);

    const pagamento = ref(null);
    const isValidIBAN = ref(false);
    const showModalBanca = ref(false);

    const iban = ref("");

    onMounted(async () => {
      isLoading.value = true;
      let resp = await apiCall({
        url: "/private/assistenza-senza-sorprese/getIban",
        data: {},
        method: "GET",
      });
      pagamento.value = resp;
      isLoading.value = false;
    });

    const saveIBAN = async function () {
      isLoading.value = true;
      let resp = await apiCall({
        url: "/private/assistenza-senza-sorprese/setIban",
        data: {
          newiban: iban.value,
        },
        method: "POST",
      });
      pagamento.value = resp.banca;
      iban.value = "";
      isValidIBAN.value = false;
      showModalBanca.value = false;
      isLoading.value = false;
    };

    const validateIBAN = async function ($event) {
      const IBAN = require("iban");
      isValidIBAN.value = IBAN.isValid($event.target.value); // false
      iban.value = $event.target.value;
    };

    const goToHome = function () {
      router.push("/");
    };

    return {
      pagamento,
      isValidIBAN,
      showModalBanca,
      iban,
      isLoading,
      saveIBAN,
      goToHome,
      last4Character,
      formatDate,
      validateIBAN,
    };
  },
};
</script>
<style></style>
