<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">
                documenti clinici<br />{{ policy_number }}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div
        class="col-md-12 pl-md-0"
        v-if="
          polizza &&
          (polizza.status === 9 || polizza.status === 11) &&
          !polizza.start_underwriting
        "
      >
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>

          <br />
          <card
            gradient="secondary"
            class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
            header-classes="bg-transparent"
            body-classes="px-lg-7"
            footer-classes="bg-transparent"
          >
            <h4 class="text-uppercase ls-1 text-primary py-3 mb-2">
              Ora che hai caricato tutti i referti medici puoi comunicarli
              cliccando il pulsante invia.
            </h4>

            <base-button
              class="btn-block"
              size="sm"
              type="success"
              @click="inviaPolizza()"
              >INVIA</base-button
            >
          </card>
          <div class="row">
            <div class="col-md-12 pl-md-0">
              <UploadEsamiCard
                :policy_number="policy_number"
                :caricato="rvm.uploaded"
                v-if="loaded && rvm && rvm.RssVisite.haveupload"
                :label="'Seleziona un file...'"
                :id="'in_rvm'"
                :description="rvm.RssVisite.descrizione"
                :initia-label="'RVM'"
                :idrsspolizzavisite="rvm.idrsspolizzavisite"
              ></UploadEsamiCard>

              <UploadEsamiCard
                :policy_number="policy_number"
                :caricato="es.uploaded"
                v-if="loaded && es && es.RssVisite.haveupload"
                :label="'Seleziona un file...'"
                :id="'in_es'"
                :description="es.RssVisite.descrizione"
                :initia-label="'ES'"
                :idrsspolizzavisite="es.idrsspolizzavisite"
              ></UploadEsamiCard>

              <UploadEsamiCard
                :policy_number="policy_number"
                :caricato="psa.uploaded"
                v-if="loaded && psa && psa.RssVisite.haveupload"
                :label="'Seleziona un file...'"
                :id="'in_psa'"
                :description="psa.RssVisite.descrizione"
                :initia-label="'PSA'"
                :idrsspolizzavisite="psa.idrsspolizzavisite"
              ></UploadEsamiCard>

              <UploadEsamiCard
                :policy_number="policy_number"
                :caricato="ecgs.uploaded"
                v-if="loaded && ecgs && ecgs.RssVisite.haveupload"
                :label="'Seleziona un file...'"
                :id="'in_ecgs'"
                :description="ecgs.RssVisite.descrizione"
                :initia-label="'ECGS'"
                :idrsspolizzavisite="ecgs.idrsspolizzavisite"
              ></UploadEsamiCard>

              <UploadEsamiCard
                :policy_number="policy_number"
                :caricato="ecgr.uploaded"
                v-if="loaded && ecgr && ecgr.RssVisite.haveupload"
                :label="'Seleziona un file...'"
                :id="'in_ecgr'"
                :description="ecgr.RssVisite.descrizione"
                :initia-label="'ECGR'"
                :idrsspolizzavisite="ecgr.idrsspolizzavisite"
              ></UploadEsamiCard>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12 pl-md-0" v-else>
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <card
            gradient="secondary"
            class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
            header-classes="bg-transparent"
            body-classes="px-lg-7"
            footer-classes="bg-transparent"
          >
            <h4 class="text-uppercase ls-1 text-primary py-3 mb-2">
              Complimenti hai confermato il caricamento di tutti i documenti
              clinici. Puoi tornare alla home cliccando sul pulsante "Torna alla
              Home"
            </h4>

            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </card>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import UploadEsamiCard from "@/components/UploadEsamiCard.vue";
import apiCall from "@/utils/api";
import { onMounted, ref } from "vue";

import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { useRoute, useRouter } from "vue-router";
import { watch } from "vue";
export default {
  bodyClass: "documenti-clinici-page",
  components: {
    UploadEsamiCard,
    Loading,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(null);

    const rvm = ref(null);
    const es = ref(null);
    const psa = ref(null);
    const ecgs = ref(null);
    const ecgr = ref(null);
    const condind = ref(null);
    const allUploaded = ref(null);
    const loaded = ref(null);

    const esami = ref(null);
    const polizza = ref(null);
    const policy_number = ref(null);

    // fetch the user information when params change
    watch(
      () => route.params.id,
      async (id) => {
        policy_number.value = id;
      }
    );

    onMounted(async () => {
      isLoading.value = true;
      policy_number.value = route.params.id;
      let resp = await apiCall({
        url: "/private/polizza/visite/" + policy_number.value,
        data: {},
        method: "GET",
      });
      esami.value = resp.visite;
      polizza.value = resp.polizza;
      elabDati();
      isLoading.value = false;
      loaded.value = true;
    });

    const elabDati = function () {
      let allUploadedTmp = true;
      for (let i = 0; i < esami.value.length; i++) {
        switch (esami.value[i].RssVisite.sigla) {
          case "RVM":
            rvm.value = esami.value[i];
            if (!rvm.value.uploaded) {
              allUploadedTmp = false;
            }
            break;
          case "ES":
            es.value = esami.value[i];
            if (!es.value.uploaded) {
              allUploadedTmp = false;
            }
            break;
          case "PSA":
            psa.value = esami.value[i];
            if (!psa.value.uploaded) {
              allUploadedTmp = false;
            }
            break;
          case "ECGS":
            ecgs.value = esami.value[i];
            if (!ecgs.value.uploaded) {
              allUploadedTmp = false;
            }
            break;
          case "ECGR":
            ecgr.value = esami.value[i];
            if (!ecgr.value.uploaded) {
              allUploadedTmp = false;
            }
            break;
        }
      }
      allUploaded.value = allUploadedTmp;
    };

    const inviaPolizza = async function () {
      isLoading.value = true;
      loaded.value = false;
      try {
        let resp = await apiCall({
          url: `/private/polizza/confirm/${polizza.value.idrsspolizza}`,
          data: null,
          method: "GET",
        });

        esami.value = resp.visite;
        polizza.value = resp.polizza;

        elabDati();
        isLoading.value = false;
        loaded.value = true;
      } catch (err) {
        isLoading.value = false;
      }
    };

    const goToHome = function () {
      router.push("/");
    };

    return {
      isLoading,
      rvm,
      es,
      psa,
      ecgs,
      ecgr,
      condind,
      allUploaded,
      loaded,
      esami,
      polizza,
      inviaPolizza,
      policy_number,
      goToHome,
    };
  },
};
</script>
<style></style>
