import axiosInstance from "../services/api";

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

const apiCall = ({ url, data, method, options }) => {
  switch (method) {
    case "GET":
      return apiCallGet({ url });
    case "POST":
      return apiCallPost({ url, data, options });
    default:
      return apiCallGet({ url });
  }
};

const apiCallGet = ({ url }) => {
  return axiosInstance
    .get(API_BASE_URL + url)
    .then((response) => response.data);
};

const apiCallPost = ({ url, data, options }) => {
  return axiosInstance
    .post(API_BASE_URL + url, data, options)
    .then((response) => response.data);
};

export default apiCall;
