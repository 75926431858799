import Notification from "@/components/Notification";
import { useToast } from "vue-toastification";

export const runToast = function (text, pos, type, ownText, ownIcon) {
  const icon = "ni ni-bell-55";
  const content = {
    component: Notification,
    props: {
      ownText: ownText,
      ownIcon: ownIcon,
      icon: icon,
      text: text,
      type: type,
    },
  };
  const toast = useToast();
  toast(content, {
    hideProgressBar: true,
    icon: false,
    closeButton: false,
    position: pos,
  });
};
