import {
  USER_REQUEST,
  USER_ERROR,
  USER_SUCCESS,
  USER_SUCCESS_LOGIN,
  PW_SET_SUCCESS,
  RESEND_PASSWORD,
  CHANGE_AGENZIA_SUCCESS,
} from "../actions/user";

import { AUTH_LOGOUT } from "../actions/auth";
import apiCall from "@/utils/api";

const state = {
  status: "",
  contact: {},
  agenzie: {},
  prodotti: {},
  statuspolizza: null,
  selectedAgency: null,
};

const getters = {
  getProfile: (state) => state.contact,
  getSelectedAgency: (state) => state.selectedAgency,
  getProdotti: (state) => state.prodotti,
  isProfileLoaded: (state) => !!state.contact.id,
};

const actions = {
  async load({ dispatch, commit }) {
    commit(USER_REQUEST);
    try {
      let resp = await apiCall({ url: "/private/user/me" });

      let respPrd = await apiCall({ url: "/private/prodotti" });

      commit(USER_SUCCESS, { user: resp, prodotti: respPrd });
    } catch (err) {
      commit(USER_ERROR, err);
      dispatch("auth/logout", null, { root: true });
    }
  },
  async remember({ commit }, { cf }) {
    commit(RESEND_PASSWORD);
    apiCall({
      url: "/public/resendPassword",
      data: { cf: cf.trim() },
      method: "POST",
    })
      .then(() => {})
      .catch(() => {});
  },
  async changepassword({ commit }, payload) {
    try {
      let resp = await apiCall({
        url: "/private/changePassword",
        data: payload,
        method: "POST",
      });
      commit(PW_SET_SUCCESS, resp);
    } catch (err) {
      commit(USER_ERROR, err);
    }
  },
  async usersuccess({ commit }, payload) {
    commit(USER_SUCCESS, payload);
  },
  async changeagenzia({ dispatch, commit }, payload) {
    try {
      commit(CHANGE_AGENZIA_SUCCESS, payload);
      let resp = await apiCall({
        url: "/private/changeAgenzia",
        data: payload,
        method: "POST",
      });
      dispatch("auth/storelogindate", resp, { root: true });
      localStorage.setItem("user-token", resp.token);
      dispatch("user/load", null, { root: true });
    } catch (err) {
      commit(USER_ERROR, err);
    }
  },
};

const mutations = {
  [USER_REQUEST]: (state) => {
    state.status = "loading";
  },
  [RESEND_PASSWORD]: () => {},
  [USER_SUCCESS]: (state, resp) => {
    state.status = "success";

    if (resp.user.contact) {
      state.contact = resp.user.contact;
    }
    if (resp.user.agenzie) {
      state.agenzie = resp.user.agenzie;
    }
    if (resp.prodotti) {
      state.prodotti = resp.prodotti;
    }

    if (!state.selectedAgency) {
      if (state.contact.Afuser.last_agency) {
        const selectedIndexAgency = resp.user.agenzie.findIndex(
          (age) => age.id == state.contact.Afuser.last_agency
        );
        state.selectedAgency = resp.user.agenzie[selectedIndexAgency];
      } else {
        state.selectedAgency = resp.user.agenzie[0];
      }
    }
  },
  [CHANGE_AGENZIA_SUCCESS]: (state, agenzia) => {
    state.selectedAgency = agenzia;
  },
  [USER_SUCCESS_LOGIN]: (state, resp) => {
    state.status = "success";

    if (resp.contact) {
      state.contact = resp.contact;
    }
  },
  [USER_ERROR]: (state) => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: (state) => {
    state.contact = {};
  },
  [PW_SET_SUCCESS]: (state, resp) => {
    state.documents = resp;
  },
};

export const userStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
