import axiosInstance from "./api";
import router from "../router/index";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      let token = localStorage.getItem("user-token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },

    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(undefined, function (err) {
    if (err.response.status === 401) {
      store.dispatch("auth/logout", null, { root: true });
      router.push("/login");
    }
    return Promise.reject(err);
  });
};
export default setup;
