<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row row-grid align-items-center mb-5">
        <div class="col-md-12" style="font-size: 12px">
          <b
            >Reddito Senza Sorprese/Assistenza Senza Sorprese sono un marchio di
            Broker Senza Sorprese srl</b
          >
          - società benefit, registrata in Italia nel Registro delle Imprese di
          Vicenza, P. IVA, num 04290780248 - R.E.A. VI - 393619 - Capitale
          sociale interamente versato € 20.000 - Via Montegrappa 37/C, 36063
          Marostica (VI) telefono:
          <a href="tel:+39 0444 1497459">+39 0444 1497459</a> email:
          <a href="mailto:info@brokersenzasorprese.com"
            >info@brokersenzasorprese.com</a
          >; reclami:
          <a href="mailto:reclami@brokersenzasorprese.com"
            >reclami@brokersenzasorprese.com</a
          >; pec:
          <a href="mailto:brokersenzasorprese@legalmail.it"
            >brokersenzasorprese@legalmail.it</a
          >
          <br />
          <br />
          <b>Broker Senza Sorprese srl</b> - società benefit è soggetta al
          controllo dell’IVASS e iscritta al Registro Unico degli Intermediari
          Assicurativi
          <a href="https://servizi.ivass.it/RuirPubblica/"
            >https://servizi.ivass.it/RuirPubblica/</a
          >
          con codice B000671254 dal 07/12/2020.
          <br />
          <br />
          <b>Assicuratore Facile srl</b> - P.IVA 04019050246 | Capitale Sociale
          € 10.000 | REA: VI 372362 IndirizzoVia Montegrappa 37/C – 36063 –
          Marostica (VI) Email :
          <a href="mailto:assicuratorefacile@legalmail.it"
            >assicuratorefacile@legalmail.it</a
          >
        </div>
      </div>
      <div class="row align-items-center justify-content-md-between">
        <div class="col-md-12">
          <ul class="nav nav-footer" style="justify-content: center">
            <li class="nav-item">
              <a
                href="https://brokersenzasorprese.com/"
                class="nav-link"
                target="_blank"
                >Broker Senza Sorprese</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://www.futurosenzasorprese.com/"
                class="nav-link"
                target="_blank"
                >Chi siamo</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://www.iubenda.com/privacy-policy/20087558"
                class="nav-link"
                target="_blank"
                >Privacy Policy</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="row align-items-center justify-content-md-between">
        <div class="col-md-12">
          <div class="copyright">
            &copy; {{ year }}
            <a href="https://satorsoftware.com" target="_blank"
              >Sator Software SRL</a
            >
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
