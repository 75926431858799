<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">
                nuova richiesta di assistenza
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div class="col-md-12 pl-md-0">
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>
          <br />
          <section class="text-center" v-if="!submitted">
            <div>
              <hr class="line-primary" />
              <br />
              <base-alert type="danger" v-if="errorForm">
                <strong
                >Hai dei cambiamenti importanti da comunicare? Richiesi con anticipo il tuo C.A.R. e tieni sempre aggiornata la tua posizione</strong
                >
              </base-alert>
              <br/>
              <form role="form" @submit.prevent="onSubmit">
                <base-alert type="danger" v-if="errorForm">
                  <strong
                    >Attenzione per procedere è necessario scegliere la
                    tipologia e una descrizione del problema</strong
                  >
                </base-alert>
                <div class="row" v-if="scegliTipologia">
                  <div class="col-md-3 align-self-center text-center">
                    <label class="labels">Tipologia</label>
                  </div>
                  <div class="col-md-9 text-left" style="text-align: left">
                    <base-radio
                      @input="changeTipologia"
                      name="1"
                      class="mb-5"
                      v-model="tipologia"
                      >Consulenza legale a distanza (telefonica o in
                      videochiamata) della durata di 30 minuti da espletarsi
                      entro 48 ore dalla richiesta e successivo rilascio di un
                      parere scritto;</base-radio
                    >
                    <base-radio
                      @input="changeTipologia"
                      name="2"
                      class="mb-5"
                      v-model="tipologia"
                      >Consulenza legale (civile e penale) e medico legale nel
                      caso di decesso o di sinistro/infortunio con invalidità
                      permanente uguale o superiore al 10 % che veda coinvolto
                      l’abbonato o un suo familiare convivente; in questo caso
                      verrà garantito il rilascio di un parere scritto legale
                      (civile o penale) e/o medico legale entro 72 ore dal
                      ricevimento della documentazione richiesta dalla Gruppo
                      Mazzini Legal Planner tramite i suoi consulenti e
                      professionisti.</base-radio
                    >
                    <base-radio
                      @input="changeTipologia"
                      name="3"
                      class="mb-5"
                      v-model="tipologia"
                      >Consulenza e assistenza legale in materia
                      contributiva/previdenziale con un plafond di € 2.000,00
                      (duemila/00) oltre accessori di legge (avendo come
                      riferimento le tariffe ministeriali degli ordini
                      professionali al valore medio).</base-radio
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 align-self-center text-center">
                    <label class="labels">Descrizione</label>
                  </div>
                  <div class="col-md-9 align-self-center">
                    <textarea
                      @input="changeDescrizione"
                      v-model="descrizione"
                      class="form-control"
                      rows="10"
                    ></textarea>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-md-12 text-center">
                    <base-button
                      nativeType="submit"
                      type="primary"
                      size="sm"
                      :disabled="enableForm"
                      @click="saveIndirizzo()"
                      >INVIA RICHIESTA</base-button
                    >
                    <base-button
                      nativeType="submit"
                      type="warning"
                      size="sm"
                      @click="goToHome"
                      >TORNA ALLA HOME</base-button
                    >
                  </div>
                </div>
              </form>
            </div>
          </section>
          <section class="text-center" v-if="submitted">
            <div>
              <hr class="line-primary" />
              <br />
              <base-alert type="success">
                <strong
                  >La richiesta è stata inviata correttamente.<br />Verrai
                  ricontattato al più presto.</strong
                >
                <br />
                <br />
                <base-button
                  nativeType="submit"
                  type="warning"
                  size="sm"
                  @click="goToHome"
                  >TORNA ALLA HOME</base-button
                >
              </base-alert>
            </div>
          </section>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import "flatpickr/dist/flatpickr.css";
// import apiCall from "@/utils/api";
import Loading from "vue-loading-overlay";
import { useRouter } from "vue-router";
import { formatDate } from "@/filters/formatDate";
import BaseAlert from "@/components/BaseAlert";
import { ref } from "vue";
import apiCall from "@/utils/api";
export default {
  name: "nuova-richiesta-assistenza",
  components: {
    Loading,
    BaseAlert,
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(false);

    const tipologia = ref(null);
    const descrizione = ref(null);
    const errorForm = ref(false);
    const enableForm = ref(true);
    const submitted = ref(false);
    const scegliTipologia = ref(false);
    const onSubmit = function () {};

    const goToHome = function () {
      router.push("/");
    };

    const changeDescrizione = function ($event) {
      if ($event && $event.target) {
        descrizione.value = $event.target.value;
      }
      validateData();
    };

    const changeTipologia = function ($event) {
      if ($event && $event.target) {
        tipologia.value = $event.target.value;
      }
      validateData();
    };

    const validateData = function () {
      errorForm.value = false;
      enableForm.value = true;
      if (tipologia.value === null || tipologia.value === "") {
        errorForm.value = true;
      }
      if (descrizione.value === null || descrizione.value === "") {
        errorForm.value = true;
      }
      if (!errorForm.value) {
        enableForm.value = false;
      }
    };
    const saveIndirizzo = async function () {
      validateData();
      if (errorForm.value == false) {
        isLoading.value = true;
        try {
          let tipol = 0;
          if (scegliTipologia.value) {
            tipol = tipologia.value;
          } else {
            tipol = 1;
          }
          const formObj = {
            descrizione: descrizione.value,
            tipologia: tipol,
          };
          await apiCall({
            url: "/private/assistenza-senza-sorprese/richiedi-assistenza",
            data: formObj,
            method: "POST",
          });
          submitted.value = true;
          isLoading.value = false;
        } catch (err) {
          isLoading.value = false;
        }
        isLoading.value = false;
      }
    };

    return {
      saveIndirizzo,
      descrizione,
      tipologia,
      changeTipologia,
      changeDescrizione,
      isLoading,
      goToHome,
      formatDate,
      onSubmit,
      errorForm,
      enableForm,
      submitted,
      scegliTipologia,
    };
  },
};
</script>
<style>
.account-settings .nav {
  text-align: left;
}

.account-settings .nav .nav-item {
  padding: 1rem 0;
}

.account-settings .nav .nav-item:not(:last-child) {
  border-bottom: 1px solid #5e72e4;
}

/* Checkmark & Strikethrough --------- */

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 100%;
}

.checkmark_container {
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: -8px;
  background: #2ecc71;
  width: 25px;
  height: 25px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.is_valid {
  color: green;
}

.is_invalid {
  color: red;
}

.checked {
  animation: draw 0.5s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
