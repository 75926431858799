<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">
                Cambio Password
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div class="col-md-12 pl-md-0">
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>
          <br />
          <section class="text-center" v-if="contact">
            <div>
              <hr class="line-primary" />
              <br />
              <form role="form" @submit.prevent="onSubmit">
                <div class="row">
                  <div class="col-md-12">
                    <base-alert type="danger" v-if="showError">
                      <strong>Attenzione!</strong> Attenzione ci sono errori nel
                      cambio password, se l'errore persiste contattare
                      l'assistenza
                    </base-alert>
                    <base-alert type="success" v-if="showOk">
                      Password cambiata con successo
                    </base-alert>
                    <ul>
                      <li
                        v-bind:class="{
                          is_valid: contains_eight_characters,
                          is_invalid: !contains_eight_characters,
                        }"
                      >
                        La lunghezza minima è di 8 caratteri
                      </li>
                      <li
                        v-bind:class="{
                          is_valid: contains_number,
                          is_invalid: !contains_eight_characters,
                        }"
                      >
                        Deve contenere un numero
                      </li>
                      <li
                        v-bind:class="{
                          is_valid: contains_uppercase,
                          is_invalid: !contains_eight_characters,
                        }"
                      >
                        Deve contenere un carattere maiuscolo
                      </li>
                      <li
                        v-bind:class="{
                          is_valid: !errorConfirmPassword,
                          is_invalid: errorConfirmPassword,
                        }"
                      >
                        La password di conferma e la password non coincidono
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 align-self-center">
                    <label class="labels">Nuova password</label>
                    <div
                      class="checkmark_container"
                      v-bind:class="{
                        show_checkmark: valid_password,
                      }"
                    >
                      <svg width="50%" height="50%" viewBox="0 0 140 100">
                        <path
                          class="checkmark"
                          v-bind:class="{
                            checked: valid_password,
                          }"
                          d="M10,50 l25,40 l95,-70"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="col-md-9 align-self-center">
                    <base-input
                      @input="validatePassword"
                      id="password"
                      name="password"
                      type="password"
                      v-model="password"
                    ></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 align-self-center">
                    <label class="labels">Conferma password</label>
                    <div
                      class="checkmark_container"
                      v-bind:class="{
                        show_checkmark: !errorConfirmPassword,
                      }"
                    >
                      <svg width="50%" height="50%" viewBox="0 0 140 100">
                        <path
                          class="checkmark"
                          v-bind:class="{
                            checked: !errorConfirmPassword,
                          }"
                          d="M10,50 l25,40 l95,-70"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="col-md-9 align-self-center">
                    <base-input
                      @input="validateConfirmPassword"
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                    ></base-input>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-md-12">
                    <base-button
                      nativeType="submit"
                      type="primary"
                      size="sm"
                      :disabled="errorConfirmPassword || !valid_password"
                      @click="changePassword"
                      >Salva Nuova Password</base-button
                    >
                  </div>
                </div>
              </form>
            </div>
          </section>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import "flatpickr/dist/flatpickr.css";
import apiCall from "@/utils/api";
import Loading from "vue-loading-overlay";
import { useRouter } from "vue-router";
import { onMounted, ref, watch } from "vue";
import { formatDate } from "@/filters/formatDate";
import BaseAlert from "@/components/BaseAlert";
export default {
  name: "change-password",
  components: {
    Loading,
    BaseAlert,
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(false);

    const contact = ref(null);
    const contains_eight_characters = ref(false);
    const password_length = ref(0);
    const contains_number = ref(false);
    const contains_special_character = ref(false);
    const contains_uppercase = ref(false);
    const valid_password = ref(false);
    const showError = ref(false);
    const showOk = ref(false);
    const errorConfirmPassword = ref(false);
    const confirmPassword = ref("");
    const password = ref("");

    const onSubmit = function () {
      if (!errorConfirmPassword.value && valid_password.value) {
        changePassword();
      }
    };

    onMounted(async () => {
      isLoading.value = true;
      let resp = await apiCall({
        url: "/private/user/me",
        data: {},
        method: "GET",
      });

      contact.value = resp.contact;
      isLoading.value = false;
    });
    watch(password, () => {});

    const validateConfirmPassword = function ($event) {
      confirmPassword.value = $event.target.value;
      errorConfirmPassword.value = false;
      checkPassword();
      if (password.value !== confirmPassword.value) {
        errorConfirmPassword.value = true;
      }
    };

    const validatePassword = function ($event) {
      password.value = $event.target.value;
      errorConfirmPassword.value = false;
      checkPassword();
      if (password.value !== confirmPassword.value) {
        errorConfirmPassword.value = true;
      }
    };
    watch(
      confirmPassword,
      (val) => {
        errorConfirmPassword.value = false;
        if (val !== password.value) {
          errorConfirmPassword.value = true;
        }
      },
      { deep: true }
    );

    const goToHome = function () {
      router.push("/");
    };

    const checkPassword = function () {
      password_length.value = password.value.length;
      // const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      if (password_length.value > 8) {
        contains_eight_characters.value = true;
      } else {
        contains_eight_characters.value = false;
      }

      contains_number.value = /\d/.test(password.value);
      contains_uppercase.value = /[A-Z]/.test(password.value);
      contains_special_character.value = true; //format.test(this.password);

      if (
        contains_eight_characters.value === true &&
        contains_special_character.value === true &&
        contains_uppercase.value === true &&
        contains_number.value === true
      ) {
        valid_password.value = true;
      } else {
        valid_password.value = false;
      }
    };

    const changePassword = async function () {
      isLoading.value = true;
      showError.value = false;
      showOk.value = false;
      try {
        let resp = await apiCall({
          url: "/private/changePassword",
          data: {
            newpwd: password.value,
            confirmpwd: confirmPassword.value,
          },
          method: "POST",
        });
        if (resp && resp.msg === "OK") {
          password.value = "";
          confirmPassword.value = "";
          showError.value = false;
          showOk.value = true;
        } else {
          showOk.value = false;
          showError.value = true;
        }
      } catch (err) {
        showOk.value = false;
        showError.value = true;
      }
      isLoading.value = false;
    };

    return {
      validateConfirmPassword,
      validatePassword,
      password,
      confirmPassword,
      errorConfirmPassword,
      contains_eight_characters,
      password_length,
      contains_number,
      contains_special_character,
      contains_uppercase,
      valid_password,
      showError,
      showOk,
      checkPassword,
      changePassword,
      contact,
      isLoading,
      goToHome,
      formatDate,
      onSubmit,
    };
  },
};
</script>
<style>
.account-settings .nav {
  text-align: left;
}

.account-settings .nav .nav-item {
  padding: 1rem 0;
}

.account-settings .nav .nav-item:not(:last-child) {
  border-bottom: 1px solid #5e72e4;
}

/* Checkmark & Strikethrough --------- */

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 100%;
}

.checkmark_container {
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: -8px;
  background: #2ecc71;
  width: 25px;
  height: 25px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.is_valid {
  color: green;
}

.is_invalid {
  color: red;
}

.checked {
  animation: draw 0.5s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
