<template>
  <div>
    <!-- Header -->
    <div
      class="header skew-separator py-7 py-lg-8 pt-lg-9"
      style="
        background-color: #f1f4f5;
        background-size: cover;
        background-position: 50%;
        width: 100%;
        height: 100%;
        z-index: -1;
      "
    ></div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <img style="width: 20em" src="img/fss/lfss.png" alt="logo" />
              </div>
              <div class="text-center text-muted mb-4">
                <h2>
                  Benvenuto!<br /><small>
                    Inserisci il tuo codice fiscale, riceverai la nuova password
                    via SMS sul tuo numero di cellulare. Una volta fatto
                    l'accesso potrai sempre cambiarla.</small
                  >
                </h2>
              </div>
              <base-alert type="danger" v-if="formError">
                <strong>Attenzione!</strong> Nome utente o password errati.
              </base-alert>
              <form role="form" @submit.prevent="onSubmit">
                <div class="form-group mb-3">
                  <base-input
                    name="cf"
                    alternative
                    required
                    placeholder="Codice fiscale"
                    :maxlength="16"
                    addonLeftIcon="ni ni-badge"
                  ></base-input>
                </div>
                <div class="text-center">
                  <base-button
                    v-bind:class="{ disabled: isSubmitting }"
                    type="primary"
                    :tag="'button'"
                    :nativeType="'submit'"
                    class="my-4"
                    >INVIA NUOVA PASSWORD</base-button
                  >
                  <hr />
                  <base-button
                    v-bind:class="{ disabled: isSubmitting }"
                    type="primary"
                    :tag="'button'"
                    class="my-4 mt-3"
                    @click="goToLogin"
                    >TORNA AL LOGIN</base-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from "vue";
import BaseAlert from "@/components/BaseAlert";
import { useForm, useField } from "vee-validate";
import { useStore } from "vuex";
import * as yup from "yup";
import { useRouter } from "vue-router";

export default {
  name: "Ricorda Password",
  bodyClass: "login-page",
  components: {
    BaseAlert,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const formSchema = yup.object({
      cf: yup.string().required().label("Codice Fiscale"),
    });

    const { handleSubmit, isSubmitting } = useForm({
      initialValues: {
        cf: "",
      },
      validationSchema: formSchema,
    });

    let formError = ref(false);

    const { value: cf } = useField("cf");

    const onSubmit = handleSubmit((values) => {
      formError.value = false;
      store.dispatch("auth/remember", values);
    });

    store.watch(
      (state) => state.auth.status,
      (status) => {
        if (status === "error") {
          formError.value = true;
        }
      }
    );

    store.watch(
      (state, getters) => getters["auth/isAuthenticated"],
      (isAuthenticated) => {
        if (isAuthenticated) {
          router.push("/");
        }
      }
    );

    const goToLogin = () => {
      router.push("/login");
    };

    watch(cf, () => {
      formError.value = false;
    });

    return {
      onSubmit,
      isSubmitting,
      formError,
      goToLogin,
    };
  },
};
</script>
<style></style>
