<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">
                Privacy {{ nomeAgenzia }}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div class="col-md-12 pl-md-0">
        <card
          v-if="contactprivacy"
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>
          <br />
          <section class="text-left">
            <div style="padding: 15px" v-html="privacyagenzia.documento"></div>
            <div style="padding: 15px">
              <table>
                <tr>
                  <td colspan="3" style="text-align: center; font-weight: bold">
                    Consenso al trattamento dei dati personali
                  </td>
                </tr>
                <tr>
                  <td colspan="3" v-html="privacyagenzia.ctp"></td>
                </tr>
                <tr>
                  <td colspan="3" style="text-align: center">
                    <div class="form-check">
                      <label class="form-check-label" for="trattamento">
                        Acconsento
                      </label>
                      <br />
                      <base-switch
                        v-model="contactprivacy.trattamento"
                        type="primary"
                        offText="No"
                        onText="Si"
                        @input="changePrivacy($event, 'trattamento')"
                      ></base-switch>
                    </div>
                  </td>
                </tr>
              </table>
              <div id="dtrattamento" v-if="showOtherTrattamento" class="mt-3">
                <table style="width: 100%">
                  <tr>
                    <td colspan="3" v-html="privacyagenzia.ctp1"></td>
                  </tr>
                </table>
                <hr />
                <table style="width: 100%">
                  <tr>
                    <td
                      colspan="2"
                      style="width: 95%"
                      v-html="privacyagenzia.commerciale"
                    ></td>
                    <td valign="middle" style="text-align: center">
                      <base-switch
                        v-model="contactprivacy.commerciale"
                        type="primary"
                        offText="No"
                        onText="Si"
                        @input="changePrivacy($event, 'commerciale')"
                      ></base-switch>
                    </td>
                  </tr>
                </table>
                <br /><br />
                <div v-if="showOtherCommerciale">
                  <table style="width: 30%" id="tblcommerciale">
                    <tr>
                      <td>EMAIL</td>
                      <td>
                        <base-switch
                          v-model="contactprivacy.email"
                          type="primary"
                          offText="No"
                          onText="Si"
                          @input="changePrivacy($event, 'email')"
                        ></base-switch>
                      </td>
                    </tr>

                    <tr>
                      <td>SMS</td>
                      <td>
                        <base-switch
                          v-model="contactprivacy.sms"
                          type="primary"
                          offText="No"
                          onText="Si"
                          @input="changePrivacy($event, 'sms')"
                        ></base-switch>
                      </td>
                    </tr>

                    <tr>
                      <td>POSTA</td>
                      <td class="text-left">
                        <base-switch
                          v-model="contactprivacy.posta"
                          type="primary"
                          offText="No"
                          onText="Si"
                          @input="changePrivacy($event, 'posta')"
                        ></base-switch>
                      </td>
                    </tr>

                    <tr>
                      <td>TELEFONO</td>
                      <td>
                        <base-switch
                          v-model="contactprivacy.telefono"
                          type="primary"
                          offText="No"
                          onText="Si"
                          @input="changePrivacy($event, 'telefono')"
                        ></base-switch>
                      </td>
                    </tr>
                  </table>
                </div>
                <br /><br />

                <table style="width: 100%">
                  <tr>
                    <td
                      colspan="2"
                      width="95%"
                      v-html="privacyagenzia.sogg_terzi"
                    ></td>
                    <td valign="middle" style="text-align: center">
                      <base-switch
                        v-model="contactprivacy.soggettiterzi"
                        type="primary"
                        offText="No"
                        onText="Si"
                        @input="changePrivacy($event, 'soggettiterzi')"
                      ></base-switch>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      width="95%"
                      v-html="privacyagenzia.profilazione"
                    ></td>
                    <td valign="middle" style="text-align: center">
                      <base-switch
                        v-model="contactprivacy.profilazione"
                        type="primary"
                        offText="No"
                        onText="Si"
                        @input="changePrivacy($event, 'profilazione')"
                      ></base-switch>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </section>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import "flatpickr/dist/flatpickr.css";
import apiCall from "@/utils/api";
import Loading from "vue-loading-overlay";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { formatDate } from "@/filters/formatDate";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";
export default {
  name: "privacy-bss",
  components: {
    Loading,
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(false);

    const nomeAgenzia = ref("Agenzia di test");
    const showOtherTrattamento = ref(false);
    const showOtherCommerciale = ref(false);
    const privacyagenzia = ref({});
    const contactprivacy = ref({});

    const onSubmit = function () {
      // store.dispatch("auth/login", values);
      // formError.value = false;
    };

    onMounted(async () => {
      isLoading.value = true;
      let resp = await apiCall({
        url: "/private/user/get-privacy-agenzia",
        data: {},
        method: "GET",
      });

      nomeAgenzia.value = resp.client.description;
      privacyagenzia.value = resp.privacy;

      contactprivacy.value = resp.contactprivacy;

      showOtherTrattamento.value = contactprivacy.value.trattamento;
      showOtherCommerciale.value = contactprivacy.value.commerciale;

      isLoading.value = false;
    });

    const goToHome = function () {
      router.push("/");
    };

    const changePrivacy = async function ($event, key) {
      if (typeof $event === "boolean") {
        isLoading.value = true;
        if (key === "trattamento") {
          showOtherTrattamento.value = $event;
          if ($event === 0) {
            contactprivacy.value.commerciale = false;
            contactprivacy.value.email = false;
            contactprivacy.value.telefono = false;
            contactprivacy.value.sms = false;
            contactprivacy.value.posta = false;
            contactprivacy.value.soggettiterzi = false;
            contactprivacy.value.profilazione = false;
          }
        }

        if (key === "commerciale") {
          showOtherCommerciale.value = $event;
          if ($event === 0) {
            contactprivacy.value.commerciale = false;
            contactprivacy.value.email = false;
            contactprivacy.value.telefono = false;
            contactprivacy.value.sms = false;
            contactprivacy.value.posta = false;
          }
        }

        await apiCall({
          url: "/private/user/set-privacy-agenzia",
          data: {
            valore: $event,
            etichetta: key,
          },
          method: "POST",
        });

        isLoading.value = false;
      }
    };

    return {
      showOtherTrattamento,
      nomeAgenzia,
      changePrivacy,
      showOtherCommerciale,
      isLoading,
      contactprivacy,
      privacyagenzia,
      goToHome,
      formatDate,
      onSubmit,
    };
  },
};
</script>
<style>
.account-settings .nav {
  text-align: left;
}

.account-settings .nav .nav-item {
  padding: 1rem 0;
}

.account-settings .nav .nav-item:not(:last-child) {
  border-bottom: 1px solid #5e72e4;
}

/* Checkmark & Strikethrough --------- */

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 100%;
}

.checkmark_container {
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: -8px;
  background: #2ecc71;
  width: 25px;
  height: 25px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.is_valid {
  color: green;
}

.is_invalid {
  color: red;
}

.checked {
  animation: draw 0.5s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
