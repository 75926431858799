<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">Cambio Foto</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div class="col-md-12 pl-md-0">
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>
          <br />
          <section class="text-center" v-if="contact">
            <div
              class="fileinput fileinput-new text-center"
              data-provides="fileinput"
            >
              <image-upload
                type="avatar"
                select-text="Aggiorna Foto"
                changeText="Salva"
                removeText="Annulla"
                @input="onAvatarChange"
                :src="imageURL"
                btnClasses="btn-primary btn-sm btn-round mt-3"
              />
            </div>
            <h3 class="title mt-4">
              {{ contact.nome }}
              {{ contact.cognome }}
            </h3>
            <small>email: {{ contact.email }}</small>
          </section>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import ImageUpload from "@/components/ImageUpload";
import "flatpickr/dist/flatpickr.css";
import apiCall from "@/utils/api";
import Loading from "vue-loading-overlay";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { formatDate } from "@/filters/formatDate";
export default {
  name: "foto-profilo",
  components: {
    Loading,
    ImageUpload,
  },
  setup: function () {
    const router = useRouter();
    const isLoading = ref(false);

    const contact = ref(null);
    const imageURL = ref(null);

    onMounted(async () => {
      isLoading.value = true;
      let resp = await apiCall({
        url: "/private/user/me",
        data: {},
        method: "GET",
      });

      if (resp.contact.photo) {
        imageURL.value = resp.contact.photo;
      }

      contact.value = resp.contact;
      isLoading.value = false;
    });

    const onImageChange = async function (file) {
      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("name", file.fileName);

      await apiCall({
        url: "/private/user/photo",
        data: formData,
        method: "POST",
      });
    };

    const onAvatarChange = async function (file) {
      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("name", file.fileName);

      await apiCall({
        url: "/private/user/photo",
        data: formData,
        method: "POST",
      });
    };

    const goToHome = function () {
      router.push("/");
    };

    return {
      onImageChange,
      onAvatarChange,
      imageURL,
      contact,
      isLoading,
      goToHome,
      formatDate,
    };
  },
};
</script>
<style>
.account-settings .nav {
  text-align: left;
}

.account-settings .nav .nav-item {
  padding: 1rem 0;
}

.account-settings .nav .nav-item:not(:last-child) {
  border-bottom: 1px solid #5e72e4;
}

/* Checkmark & Strikethrough --------- */

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 100%;
}

.checkmark_container {
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: -8px;
  background: #2ecc71;
  width: 25px;
  height: 25px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.is_valid {
  color: green;
}

.is_invalid {
  color: red;
}

.checked {
  animation: draw 0.5s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
