<template>
  <div>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">
                Scegli il meglio per te e la tua famiglia
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <template
        v-for="(prodotto, index) in prodotti"
        :key="prodotto.idprodotto"
      >
        <dynamic-component
          :component-name="prodotto.vuecomp"
          :prodotto="prodotto"
          :index="index"
        />
      </template>
    </div>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { watch, ref, onMounted } from "vue";
import DynamicComponent from "@/views/Components/DynamicComponent";
import { useStore } from "vuex";
export default {
  name: "home-page",
  components: { DynamicComponent },
  setup() {
    const prodotti = ref();
    const store = useStore();

    onMounted(async () => {
      let resp = await apiCall({
        url: "/private/prodotti",
        data: {},
        method: "GET",
      });

      prodotti.value = resp;
    });

    watch(prodotti, () => {}, { deep: true });

    store.watch(
      (state) => state.user.prodotti,
      (prd) => {
        prodotti.value = prd;
      }
    );

    return {
      prodotti,
    };
  },
};
</script>
<style>
.opacityback:before {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
