<template>
  <div>
    <!-- Header -->
    <div
      class="header skew-separator py-7 py-lg-8 pt-lg-9"
      style="
        background-color: #f1f4f5;
        background-size: cover;
        background-position: 50%;
        width: 100%;
        height: 100%;
        z-index: -1;
      "
    ></div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <loading :active="isSubmitting" :is-full-page="true"></loading>
              <div class="text-center text-muted mb-4">
                <img style="width: 20em" src="img/fss/lfss.png" alt="logo" />
              </div>
              <div class="text-center text-muted mb-4">
                <h2>
                  Benvenuto!<br /><small>
                    Esegui il login per entrare nella tua area riservata.</small
                  >
                </h2>
              </div>
              <base-alert type="danger" v-if="formError">
                <strong>Attenzione!</strong> Nome utente o password errati.
              </base-alert>
              <form role="form" @submit.prevent="onSubmit">
                <div class="form-group mb-3">
                  <base-input
                    name="cf"
                    alternative
                    required
                    placeholder="Codice fiscale"
                    :maxlength="16"
                    addonLeftIcon="ni ni-badge"
                  ></base-input>
                </div>
                <div class="form-group">
                  <base-input
                    name="password"
                    autocomplete="current-password"
                    alternative
                    required
                    type="password"
                    placeholder="Password"
                    addonLeftIcon="ni ni-lock-circle-open"
                  ></base-input>
                </div>
                <div class="custom-control-alternative pl-0">
                  <a href="javascript:;" @click="goToForgot"
                    >Password dimenticata?</a
                  >
                </div>
                <div class="text-center">
                  <base-button
                    v-bind:class="{ disabled: isSubmitting }"
                    type="primary"
                    :tag="'button'"
                    :nativeType="'submit'"
                    class="my-4"
                    >Login</base-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from "vue";
import BaseAlert from "@/components/BaseAlert";
import { useForm, useField } from "vee-validate";
import { useStore } from "vuex";
import * as yup from "yup";
import { useRouter } from "vue-router";
import Loading from "vue-loading-overlay";
export default {
  name: "Login",
  bodyClass: "login-page",
  components: {
    BaseAlert,
    Loading,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isLoading = ref(false);

    const formSchema = yup.object({
      cf: yup.string().required().label("Codice Fiscale"),
      password: yup.string().required().label("Password"),
    });

    const { handleSubmit, isSubmitting } = useForm({
      initialValues: {
        cf: "",
        password: "",
      },
      validationSchema: formSchema,
    });

    let formError = ref(false);

    const { value: cf } = useField("cf");
    const { value: password } = useField("password");

    const onSubmit = handleSubmit((values) => {
      isLoading.value = true;
      formError.value = false;
      store.dispatch("auth/login", values);
      isLoading.value = false;
    });

    store.watch(
      (state) => state.auth.status,
      (status) => {
        if (status === "error") {
          formError.value = true;
        }
      }
    );

    store.watch(
      (state, getters) => getters["auth/isAuthenticated"],
      (isAuthenticated) => {
        if (isAuthenticated) {
          router.push("/");
        }
      }
    );

    const goToForgot = () => {
      router.push("/forgot");
    };

    watch(cf, () => {
      formError.value = false;
    });

    watch(password, () => {
      formError.value = false;
    });

    return {
      onSubmit,
      isSubmitting,
      formError,
      goToForgot,
      isLoading,
    };
  },
};
</script>
<style></style>
