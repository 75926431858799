<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">
                storico diagnosi
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div class="col-md-12 pl-md-0">
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>
          <br />
          <div class="table-responsive">
            <table class="table text-left">
              <thead class="thead-light">
                <tr>
                  <th scope="col">Diagnosi</th>
                  <th scope="col">Fabbisogni</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="diagnosi in documents"
                  :key="diagnosi.idlogcontattodiagnosi"
                >
                  <th scope="row">
                    <h4>
                      Numero
                      {{ diagnosi.numerounivoco }}<br />
                      Redatta da
                      {{ diagnosi.LogContatto.Users.username }}<br />
                      il
                      {{ formatDate(diagnosi.LogContatto.data_inserimento) }}
                    </h4>
                  </th>
                  <th scope="row">
                    <table
                      class="table table-primary text-white table-bordered"
                    >
                      <tbody>
                        <tr>
                          <td>Premorienza</td>
                          <td>
                            {{
                              toCurrency(diagnosi.sommadaassicurarepremorienza)
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Invalidità</td>
                          <td>
                            {{
                              toCurrency(diagnosi.sommadaassicurareinvalidita)
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Non autosufficienza</td>
                          <td>
                            {{
                              toCurrency(
                                diagnosi.sommadaassicurarenonautosufficienza
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </th>
                  <td scope="row">
                    <base-button
                      class="btn-block"
                      size="sm"
                      type="success"
                      @click="openDiagnosi(diagnosi.idlogcontattodiagnosi)"
                      >Scarica</base-button
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { saveAs } from "file-saver";
import apiCall from "@/utils/api";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
// Import component
import Loading from "vue-loading-overlay";
import { formatDate, toCurrency } from "@/filters/formatDate";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { runToast } from "@/util/notif";
export default {
  name: "storico-diagnosi-page",
  components: {
    Loading,
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(false);

    const documents = ref(null);

    const openDiagnosi = async function (idlogcontattodiagnosi) {
      try {
        let resp = await apiCall({
          url: "/private/diagnosi/getlink/" + idlogcontattodiagnosi,
          method: "GET",
        });
        window.open(resp.url, "_blank");
      } catch (e) {
        runToast("Errore download file", "top-center", "danger");
      }
    };

    onMounted(async () => {
      isLoading.value = true;
      let resp = await apiCall({
        url: "/private/diagnosi/list",
        data: {},
        method: "GET",
      });

      documents.value = resp;
      isLoading.value = false;
    });

    const getFileFromBase64 = function (string64, fileName) {
      const trimmedString = string64.replace("dataimage/jpegbase64", "");
      const imageContent = atob(trimmedString);
      const buffer = new ArrayBuffer(imageContent.length);
      const view = new Uint8Array(buffer);

      for (let n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n);
      }
      const type = "application/pdf;charset=utf-8";
      const blob = new Blob([buffer], { type });
      return new File([blob], fileName, {
        lastModified: new Date().getTime(),
        type,
      });
    };
    const downloadFile = async function (documento) {
      try {
        let respDoc = await apiCall({
          url: "/private/polizza/getDocument",
          data: documento,
          method: "POST",
        });
        const strFileName = documento.description + ".pdf";
        const fileEncoded = this.getFileFromBase64(respDoc.data, strFileName);
        saveAs(fileEncoded, strFileName);
      } catch (err) {
        runToast("Errore download file", "top-center", "danger");
      }
    };

    const goToHome = function () {
      router.push("/");
    };

    return {
      documents,
      isLoading,
      openDiagnosi,
      formatDate,
      toCurrency,
      getFileFromBase64,
      downloadFile,
      goToHome,
    };
  },
};
</script>
<style></style>
