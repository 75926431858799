<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">
                Fabbisogni<br />{{ policy_number }}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div class="col-md-12 pl-md-0">
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>
          <br />
          <card
            gradient="blue"
            class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
            header-classes="bg-transparent"
            body-classes="px-lg-7"
            footer-classes="bg-transparent"
          >
            <div class="row">
              <div class="col-md-12" v-if="polizza">
                <h2 class="text-white mb-0">
                  {{
                    decodeDiagnosiType(
                      this.polizza.RssPreventivoTestata.LogContattoDiagnosi
                        .LogContatto.idlogtype
                    )
                  }}<br />
                  Numero
                  {{
                    polizza.RssPreventivoTestata.LogContattoDiagnosi
                      .numerounivoco
                  }}<br />
                  Redatta da
                  {{
                    polizza.RssPreventivoTestata.LogContattoDiagnosi.LogContatto
                      .Users.username
                  }}<br />
                  il
                  {{
                    formatDate(
                      polizza.RssPreventivoTestata.LogContattoDiagnosi
                        .LogContatto.data_inserimento
                    )
                  }}<br />
                  sono emersi i seguenti fabbisogni:
                </h2>
                <div class="table-responsive">
                  <table
                    class="table table-primary text-white table-bordered mt-3"
                  >
                    <tbody>
                      <tr>
                        <td>Premorienza</td>
                        <td>
                          {{
                            toCurrency(
                              polizza.RssPreventivoTestata.LogContattoDiagnosi
                                .sommadaassicurarepremorienza
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Invalidità</td>
                        <td>
                          {{
                            toCurrency(
                              polizza.RssPreventivoTestata.LogContattoDiagnosi
                                .sommadaassicurareinvalidita
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Non autosufficienza</td>
                        <td>
                          {{
                            toCurrency(
                              polizza.RssPreventivoTestata.LogContattoDiagnosi
                                .sommadaassicurarenonautosufficienza
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <base-button
                  type="primary"
                  :class="index % 2 === 0 ? 'text-white' : 'text-black'"
                  @click="openDiagnosi()"
                  >SCARICA DIAGNOSI</base-button
                >
              </div>
            </div>
          </card>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { formatDate, toCurrency } from "@/filters/formatDate";
import { runToast } from "@/util/notif";
export default {
  name: "fabbisogni-page",
  components: {
    Loading,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const isLoading = ref(false);
    const polizza = ref(null);
    const tariffa = ref(null);
    const tariffe = ref(null);
    const policy_number = ref(null);
    const statuspolizza = ref(null);

    onMounted(async () => {
      isLoading.value = true;
      policy_number.value = route.params.id;
      let resp = await apiCall({
        url: "/private/polizza/data/" + policy_number.value,
        data: {},
        method: "GET",
      });

      polizza.value = resp.polizza;
      isLoading.value = false;
    });

    const openDiagnosi = async function () {
      try {
        let resp = await apiCall({
          url: "/private/polizza/linkdiagnosi/" + policy_number.value,
          method: "GET",
        });
        window.open(resp.url, "_blank");
      } catch (e) {
        runToast("Errore download file", "top-center", "danger");
      }
    };

    const decodeDiagnosiType = function (type) {
      switch (type) {
        case 11:
          return "Diagnosi Futuro senza sorprese".toUpperCase();
        case 22:
          return "Diagnosi Futuro senza sorprese".toUpperCase();
        case 12:
          return "Contributi senza sorprese".toUpperCase();
        case 23:
          return "Contributi senza sorprese".toUpperCase();
      }
    };

    const goToHome = function () {
      router.push("/");
    };

    return {
      polizza,
      tariffe,
      tariffa,
      statuspolizza,
      isLoading,
      openDiagnosi,
      policy_number,
      decodeDiagnosiType,
      formatDate,
      toCurrency,
      goToHome,
    };
  },
};
</script>
<style></style>
