import { createStore } from "vuex";

import { userStore } from "./modules/user";
import { authStore } from "./modules/auth";
import { portalStore } from "./modules/portal";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  modules: {
    user: userStore,
    auth: authStore,
    portal: portalStore,
  },
  strict: debug,
});
