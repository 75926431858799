<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">
                Documento Identità
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div class="col-md-12 pl-md-0">
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>
          <br />
          <section class="text-center">
            <div>
              <hr class="line-primary" />
              <br />
              <form role="form" @submit.prevent="onSubmit">
                <base-alert
                  type="danger"
                  v-if="errorDocuments.formError && enabledUpdateDocument"
                >
                  <strong>Attenzione!</strong><br />
                  <ul>
                    <li v-for="errore in errorDocuments.errors" :key="errore">
                      {{ errore }}
                    </li>
                  </ul>
                </base-alert>
                <div class="row">
                  <div class="col-md-3 align-self-center">
                    <label class="labels">Numero</label>
                  </div>
                  <div class="col-md-9 align-self-center">
                    <base-input-normal
                      id="idNumberDocs"
                      name="idNumberDocs"
                      :disabled="!enabledUpdateDocument"
                      :value="dociden.documento.idNumber"
                      @input="changeIdNumber"
                      :valid="
                        enabledUpdateDocument ? errorDocuments.idNumber : null
                      "
                    ></base-input-normal>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 align-self-center">
                    <label class="labels">Data emissione</label>
                  </div>
                  <div class="col-md-9 align-self-center">
                    <base-input
                      name="idIssueDateWrapper"
                      addon-left-icon="ni ni-calendar-grid-58"
                    >
                      <flat-picker
                        :config="configDate"
                        :disabled="!enabledUpdateDocument"
                        class="form-control datepicker"
                        v-model="dociden.documento.idIssueDate"
                        :valid="
                          enabledUpdateDocument
                            ? errorDocuments.idIssueDate
                            : null
                        "
                        :maxDate="Date.now()"
                      >
                      </flat-picker>
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 align-self-center">
                    <label class="labels">Data scadenza</label>
                  </div>
                  <div class="col-md-9 align-self-center">
                    <base-input-normal
                      name="idExpiryDateWrapper"
                      addon-left-icon="ni ni-calendar-grid-58"
                    >
                      <flat-picker
                        :config="configDate"
                        :disabled="!enabledUpdateDocument"
                        class="form-control datepicker"
                        v-model="dociden.documento.idExpiryDate"
                        :minDate="Date.now()"
                        :valid="
                          enabledUpdateDocument
                            ? errorDocuments.idExpiryDate
                            : null
                        "
                      >
                      </flat-picker>
                    </base-input-normal>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 align-self-center">
                    <label class="labels">Comune di emissione</label>
                  </div>
                  <div class="col-md-9 align-self-center">
                    <base-input-normal
                      id="idIssueTown"
                      name="idIssueTown"
                      :disabled="!enabledUpdateDocument"
                      :value="dociden.documento.idIssueTown"
                      @input="changeIdIssueTown"
                      :valid="
                        enabledUpdateDocument
                          ? errorDocuments.idIssueTown
                          : null
                      "
                    ></base-input-normal>
                  </div>
                </div>
                <div class="row" v-if="enabledUpdateDocument">
                  <div class="col-md-3 align-self-center">
                    <label class="labels">Documento PDF</label>
                  </div>
                  <div class="col-md-9 align-self-center">
                    <div class="custom-file">
                      <base-input-normal
                        type="file"
                        inputClasses="custom-file-input"
                        id="documento"
                        lang="it"
                        @input="shownamefile"
                        :valid="
                          enabledUpdateDocument
                            ? errorDocuments.idIssueTown
                            : null
                        "
                      ></base-input-normal>
                      <label class="custom-file-label" for="documento">{{
                        labelDocument
                      }}</label>
                    </div>
                  </div>
                </div>

                <div class="row mt-5">
                  <div class="col-md-6">
                    <base-button
                      v-if="enabledUpdateDocument"
                      nativeType="submit"
                      type="primary"
                      size="sm"
                      :disabled="
                        enabledUpdateDocument ? errorDocuments.formError : null
                      "
                      @click="updateDocument()"
                      >Aggiorna Documento</base-button
                    >
                    <base-button
                      v-if="enabledUpdateDocument"
                      nativeType="submit"
                      type="warning"
                      size="sm"
                      @click="enabledUpdateDocument = !enabledUpdateDocument"
                      >Annulla</base-button
                    >
                    <base-button
                      v-if="!enabledUpdateDocument"
                      nativeType="submit"
                      type="primary"
                      size="sm"
                      @click="enabledUpdateDocument = !enabledUpdateDocument"
                      >Abilita Modifiche</base-button
                    >
                  </div>
                </div>
              </form>
            </div>
          </section>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import "flatpickr/dist/flatpickr.css";
import apiCall from "@/utils/api";
import Loading from "vue-loading-overlay";
import { useRouter } from "vue-router";
import { onMounted, ref, reactive } from "vue";
import { formatDate } from "@/filters/formatDate";
import BaseAlert from "@/components/BaseAlert";
import { Italian } from "flatpickr/dist/l10n/it.js";
import flatPicker from "vue-flatpickr-component";
import BaseInputNormal from "@/components/Inputs/BaseInputNormal";
export default {
  name: "documento-identita",
  components: {
    Loading,
    BaseAlert,
    flatPicker,
    BaseInputNormal,
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(false);

    const contact = ref(null);
    const enabledUpdateDocument = ref(false);
    const labelDocument = ref("");

    const configDate = reactive({
      allowInput: true,
      altFormat: "d/m/Y",
      altInput: true,
      dateFormat: "Y-m-d",
      locale: Italian,
    });

    const errorDocuments = reactive({
      errors: [],
      formError: false,
    });

    const dociden = reactive({
      documento: {
        active: null,
        idExpiryDate: null,
        idIssueDate: null,
        idIssueOffice: null,
        idIssueTown: null,
        idNumber: null,
        idType: null,
        idrsspolizza: null,
        idrsspolizzadociden: null,
        file: null,
        fileName: null,
      },
    });

    const onSubmit = function () {
      // store.dispatch("auth/login", values);
      // formError.value = false;
    };

    onMounted(async () => {
      isLoading.value = true;
      let resp = await apiCall({
        url: "/private/user/get-doc-iden",
        data: {},
        method: "GET",
      });

      dociden.documento = { ...resp.documento, file: null, fileName: null };
      isLoading.value = false;
    });

    const validateDocument = function () {
      errorDocuments.formError = false;
      errorDocuments.errors = [];
      errorDocuments.idNumber = true;
      if (dociden.documento.idNumber === "") {
        errorDocuments.errors.push("Numero documento obbligatorio");
        errorDocuments.idNumber = false;
        errorDocuments.formError = true;
      }
      errorDocuments.idIssueOffice = true;
      if (dociden.documento.idIssueOffice === "") {
        dociden.documento.idIssueOffice = "COMUNE";
      }
      errorDocuments.idIssueTown = true;
      if (dociden.documento.idIssueTown == "") {
        errorDocuments.errors.push("Comune emissione obbligatorio");
        errorDocuments.idIssueTown = false;
        errorDocuments.formError = true;
      }
      errorDocuments.idExpiryDate = true;
      if (dociden.documento.idExpiryDate == "") {
        errorDocuments.errors.push("Data scadenza obbligatoria");
        errorDocuments.idExpiryDate = false;
        errorDocuments.formError = true;
      }
      errorDocuments.idIssueDate = true;
      if (dociden.documento.idIssueDate == "") {
        errorDocuments.errors.push("Data emissione obbligatoria");
        errorDocuments.idIssueDate = false;
        errorDocuments.formError = true;
      }

      const allowedTypes = ["application/pdf"];
      errorDocuments.validFile = true;
      if (dociden.documento.file == undefined) {
        errorDocuments.errors.push("Scansione documento obbligratoria");
        errorDocuments.validFile = false;
        errorDocuments.formError = true;
      }
      if (dociden.documento.fileName == "") {
        errorDocuments.errors.push("Scansione documento obbligratoria");
        errorDocuments.validFile = false;
        errorDocuments.formError = true;
      }
      if (errorDocuments.validFile) {
        if (!allowedTypes.includes(dociden.documento.file.type)) {
          errorDocuments.errors.push(
            "Si possono caricare solo documenti PDF !!"
          );
          errorDocuments.validFile = false;
          errorDocuments.formError = true;
        }
        if (dociden.documento.file.size > 2000000) {
          errorDocuments.errors.push(
            "File troppo grosso, il file può essere al massimo di 2MB"
          );
          errorDocuments.validFile = false;
          errorDocuments.formError = true;
        }
      }
      return errorDocuments.formError;
    };
    const shownamefile = function ($event) {
      if ($event && $event.target) {
        if ($event.target.files.length > 0) {
          dociden.documento.fileName = $event.target.files[0].name;
          dociden.documento.file = $event.target.files[0];
          labelDocument.value = dociden.documento.fileName;
          validateDocument();
        }
      }
    };

    const updateDocument = async function () {
      validateDocument();
      if (errorDocuments.errors.length == 0) {
        isLoading.value = true;
        try {
          const formData = new FormData();
          formData.append(
            "file",
            dociden.documento.file,
            dociden.documento.fileName
          );
          formData.append("name", dociden.documento.fileName);
          formData.append("idExpiryDate", dociden.documento.idExpiryDate);
          formData.append("idIssueDate", dociden.documento.idIssueDate);
          formData.append("idIssueOffice", dociden.documento.idIssueOffice);
          formData.append("idIssueTown", dociden.documento.idIssueTown);
          formData.append("idNumber", dociden.documento.idNumber);
          formData.append("idType", dociden.documento.idType);
          formData.append("idrsspolizza", dociden.documento.idrsspolizza);
          await apiCall({
            url: "/private/setNewDocument",
            data: formData,
            method: "POST",
          });
          isLoading.value = false;
          enabledUpdateDocument.value = false;
        } catch (err) {
          isLoading.value = false;
          enabledUpdateDocument.value = false;
        }
        isLoading.value = false;
        enabledUpdateDocument.value = false;
      }
    };
    const goToHome = function () {
      router.push("/");
    };

    const changeIdNumber = function ($event) {
      if ($event && $event.target) {
        dociden.documento.idNumber = $event.target.value;
        validateDocument();
      }
    };

    const changeIdIssueTown = function ($event) {
      if ($event && $event.target) {
        dociden.documento.idIssueTown = $event.target.value;
        validateDocument();
      }
    };

    return {
      changeIdIssueTown,
      changeIdNumber,
      contact,
      isLoading,
      enabledUpdateDocument,
      configDate,
      errorDocuments,
      labelDocument,
      dociden,
      shownamefile,
      validateDocument,
      goToHome,
      formatDate,
      updateDocument,
      onSubmit,
    };
  },
};
</script>
<style>
.account-settings .nav {
  text-align: left;
}

.account-settings .nav .nav-item {
  padding: 1rem 0;
}

.account-settings .nav .nav-item:not(:last-child) {
  border-bottom: 1px solid #5e72e4;
}

/* Checkmark & Strikethrough --------- */

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 100%;
}

.checkmark_container {
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: -8px;
  background: #2ecc71;
  width: 25px;
  height: 25px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.is_valid {
  color: green;
}

.is_invalid {
  color: red;
}

.checked {
  animation: draw 0.5s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
