/*!

=========================================================
* Vue Argon Dashboard PRO - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueLoading from "vue-loading-overlay";
// import VueTour from "v3-tour";

require("v3-tour/dist/vue-tour.css");
import "vue-loading-overlay/dist/vue-loading.css";

import store from "./store";

const options = { containerClassName: "ct-notification" };

import setupInterceptors from "./services/setupInterceptors";
setupInterceptors(store);

const appInstance = createApp(App);

appInstance.use(store);
appInstance.use(router);
appInstance.use(Toast, options);
appInstance.use(ArgonDashboard);
// appInstance.use(VueTour);

appInstance.mount("#app");

appInstance.use(VueLoading);
