<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">Indirizzo</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div class="col-md-12 pl-md-0">
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>
          <br />
          <section class="text-center">
            <div>
              <hr class="line-primary" />
              <br />
              <form role="form" @submit.prevent="onSubmit">
                <base-alert type="danger" v-if="indirizzo.indi.formError">
                  <strong>Attenzione Dati Errati</strong>
                </base-alert>
                <div class="row">
                  <div class="col-md-3 align-self-center">
                    <label class="labels">Indirizzo</label>
                  </div>
                  <div class="col-md-9 align-self-center">
                    <base-input-normal
                      id="street"
                      name="street"
                      type="street"
                      :disabled="!enableChangeAddress"
                      @input="changeIndirizzo"
                      :value="indirizzo.indi.indirizzo"
                    ></base-input-normal>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 align-self-center">
                    <label class="labels">Comune</label>
                  </div>
                  <div class="col-md-9 align-self-center">
                    <!--   <VueTypeaheadBootstrap
                      v-model="query"
                      class="mb-3"
                      :data="comuni"
                      :serializer="(s) => s.name"
                      :disabled="!enableChangeAddress"
                      @hit="selectedComune = $event"
                      placeholder="Cerca Comune..."
                    />
                  -->
                    <SimpleTypeahead
                      id="typeahead_id"
                      placeholder="Cerca Comune..."
                      :items="comuni.lista"
                      :defaultItem="indirizzo.indi.descComune"
                      :disabled="!enableChangeAddress"
                      :minInputLength="1"
                      :itemProjection="(s) => s.name"
                      @selectItem="selectItemEventHandler"
                      @onInput="onInputEventHandler"
                      @onFocus="onFocusEventHandler"
                      @onBlur="onBlurEventHandler"
                    >
                    </SimpleTypeahead>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 align-self-center">
                    <label class="labels">CAP</label>
                  </div>
                  <div class="col-md-9 align-self-center">
                    <base-input-normal
                      id="cap"
                      name="cap"
                      type="number"
                      :disabled="!enableChangeAddress"
                      @input="changeCap"
                      :value="indirizzo.indi.cap"
                    ></base-input-normal>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-md-6">
                    <base-button
                      v-if="enableChangeAddress"
                      nativeType="submit"
                      type="primary"
                      size="sm"
                      :disabled="
                        enableChangeAddress ? indirizzo.formError : null
                      "
                      @click="saveIndirizzo()"
                      >Salva Indirizzo</base-button
                    >
                    <base-button
                      v-if="enableChangeAddress"
                      nativeType="submit"
                      type="warning"
                      size="sm"
                      @click="enableChangeAddress = !enableChangeAddress"
                      >Annulla</base-button
                    >
                    <base-button
                      v-if="!enableChangeAddress"
                      nativeType="submit"
                      type="primary"
                      size="sm"
                      @click="enableChangeAddress = !enableChangeAddress"
                      >Abilita Modifiche</base-button
                    >
                  </div>
                </div>
              </form>
            </div>
          </section>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import "flatpickr/dist/flatpickr.css";
import apiCall from "@/utils/api";
import Loading from "vue-loading-overlay";
import { useRouter } from "vue-router";
import { onMounted, ref, reactive } from "vue";
import { formatDate } from "@/filters/formatDate";
import BaseAlert from "@/components/BaseAlert";
import { Italian } from "flatpickr/dist/l10n/it.js";
import SimpleTypeahead from "@/components/vue3-simple-typeahead";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css"; //Optional default CSS
import _ from "lodash";
import BaseInputNormal from "@/components/Inputs/BaseInputNormal";
export default {
  name: "indirizzo",
  components: {
    Loading,
    BaseAlert,
    SimpleTypeahead,
    BaseInputNormal,
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(false);

    const contact = ref(null);
    const enableChangeAddress = ref(false);
    const labelDocument = ref("");

    const comuneSelezionato = ref(null);

    const comuni = reactive({ lista: [] });

    //document.getElementsByClassName("simple-typeahead-input")[0].classList.add("form-control");
    // document.getElementsByClassName("simple-typeahead-input")[0].classList.add("mb-3");
    const searchComune = _.debounce(function (newQuery) {
      apiCall({
        url: `/private/search-comune?term=${newQuery}`,
        data: null,
        method: "GET",
      }).then((res) => {
        comuni.lista = res;
      });
    });
    const configDate = reactive({
      allowInput: true,
      altFormat: "d/m/Y",
      altInput: true,
      dateFormat: "Y-m-d",
      locale: Italian,
    });

    const errorDocuments = reactive({
      errors: [],
      formError: false,
    });

    const indirizzo = reactive({
      indi: {
        formError: false,
        indirizzo: null,
        cap: null,
        comune_id: null,
        descComune: null,
      },
    });

    const onSubmit = function () {
      // store.dispatch("auth/login", values);
      // formError.value = false;
    };

    onMounted(async () => {
      isLoading.value = true;
      let resp = await apiCall({
        url: "/private/user/get-indirizzo",
        data: {},
        method: "GET",
      });

      let resp2 = await apiCall({
        url: `/private/get-comune?term=${resp.indirizzo.comune_id}`,
        data: null,
        method: "GET",
      });

      comuni.lista.push(resp2);
      comuneSelezionato.value = resp2;
      // comuneSelezionato.value=1;
      indirizzo.indi = {
        ...resp.indirizzo,
        descComune: { ...resp2 },
        formError: false,
      };
      isLoading.value = false;
    });

    const validateIndirizzo = function () {
      indirizzo.indi.formError = false;

      if (
        indirizzo.indi.indirizzo === "" ||
        indirizzo.indi.indirizzo === null ||
        indirizzo.indi.indirizzo === undefined
      ) {
        indirizzo.indi.formError = true;
      }
      if (
        indirizzo.indi.cap === "" ||
        indirizzo.indi.cap === null ||
        indirizzo.indi.cap === undefined
      ) {
        indirizzo.indi.formError = true;
      }

      if (
        comuneSelezionato.value === null ||
        comuneSelezionato.value === undefined
      ) {
        indirizzo.indi.formError = true;
      }
      return indirizzo.indi.formError;
    };

    const goToHome = function () {
      router.push("/");
    };

    const changeCap = function ($event) {
      if ($event && $event.target) {
        indirizzo.indi.cap = $event.target.value;
        validateIndirizzo();
      }
    };

    const changeIndirizzo = function ($event) {
      if ($event && $event.target) {
        indirizzo.indi.indirizzo = $event.target.value;
        validateIndirizzo();
      }
    };

    const selectItemEventHandler = function (item) {
      comuneSelezionato.value = item;
      indirizzo.indi.descComune = item;
      validateIndirizzo();
    };

    const onInputEventHandler = function (item) {
      comuneSelezionato.value = null;
      indirizzo.indi.descComune = null;
      searchComune(item.input);
      validateIndirizzo();
    };

    const onFocusEventHandler = function () {
      validateIndirizzo();
    };

    const onBlurEventHandler = function () {
      validateIndirizzo();
    };

    const saveIndirizzo = async function () {
      validateIndirizzo();
      if (indirizzo.indi.formError == false) {
        isLoading.value = true;
        try {
          const formObj = {
            indirizzo: indirizzo.indi.indirizzo,
            cap: indirizzo.indi.cap,
            comune_id: comuneSelezionato.value.id,
            comune_desc: comuneSelezionato.value.name,
          };
          await apiCall({
            url: "/private/saveIndirizzo",
            data: formObj,
            method: "POST",
          });
          isLoading.value = false;
          enableChangeAddress.value = false;
        } catch (err) {
          isLoading.value = false;
          enableChangeAddress.value = false;
        }
        isLoading.value = false;
        enableChangeAddress.value = false;
      }
    };

    return {
      saveIndirizzo,
      comuni,
      comuneSelezionato,
      selectItemEventHandler,
      onInputEventHandler,
      onFocusEventHandler,
      onBlurEventHandler,
      changeIndirizzo,
      changeCap,
      contact,
      isLoading,
      enableChangeAddress,
      configDate,
      errorDocuments,
      labelDocument,
      indirizzo,
      goToHome,
      formatDate,
      onSubmit,
    };
  },
};
</script>
<style>
.account-settings .nav {
  text-align: left;
}

.account-settings .nav .nav-item {
  padding: 1rem 0;
}

.account-settings .nav .nav-item:not(:last-child) {
  border-bottom: 1px solid #5e72e4;
}

/* Checkmark & Strikethrough --------- */

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 100%;
}

.checkmark_container {
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: -8px;
  background: #2ecc71;
  width: 25px;
  height: 25px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.is_valid {
  color: green;
}

.is_invalid {
  color: red;
}

.checked {
  animation: draw 0.5s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
