<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">
                Coperture<br />{{ policy_number }}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div class="col-md-12 pl-md-0">
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>
          <br />
          <h4 class="text-uppercase ls-1 text-primary py-3 mb-2">
            La durata della polizza è di {{ durata }} anni
          </h4>
          <div class="table-responsive">
            <table class="table text-left">
              <thead class="thead-light">
                <tr>
                  <th scope="col"><b>Anno</b></th>
                  <th scope="col"><b>Caso Morte</b></th>
                  <th scope="col"><b>Invalidità</b></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="copertura in tableCoperture" :key="copertura.anno">
                  <th scope="row">{{ copertura.anno }}</th>
                  <td>{{ toCurrency(copertura.cov) }}</td>
                  <td>{{ toCurrency(copertura.inv) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { toCurrency } from "@/filters/formatDate";
export default {
  name: "coperture-page",
  components: {
    Loading,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(false);

    const polizza = ref(null);
    const tariffa = ref(null);
    const tariffe = ref(null);
    const tableCoperture = ref(null);
    const durata = ref(null);
    const policy_number = ref(null);

    onMounted(async () => {
      isLoading.value = true;
      policy_number.value = route.params.id;
      let resp = await apiCall({
        url: "/private/polizza/data/" + policy_number.value,
        data: {},
        method: "GET",
      });

      polizza.value = resp.polizza;
      if (polizza.value) {
        const initialYear = parseInt(
          polizza.value.RssPreventivoTestata.origin.substring(0, 4)
        );
        durata.value = polizza.value.RssPreventivoTestata.duration;
        for (
          let i = 0;
          i < polizza.value.RssPreventivoTestata.RssPreventivoTariffa.length;
          i++
        ) {
          if (
            polizza.value.RssPreventivoTestata.RssPreventivoTariffa[i]
              .tariff === polizza.value.RssPreventivoTestata.tariff_scelta
          ) {
            tariffa.value =
              polizza.value.RssPreventivoTestata.RssPreventivoTariffa[i];

            // Leggo tariffe evolution
            let respTariffe = await apiCall({
              url:
                "/private/polizza/tariffe-evolution/" +
                polizza.value.RssPreventivoTestata.RssPreventivoTariffa[i]
                  .idrsspreventivotariffa,
              data: {},
              method: "GET",
            });
            tariffe.value = respTariffe.tariffa;
          }
        }
        tableCoperture.value = [];
        for (let i = 0; i < tariffe.value.length; i++) {
          tableCoperture.value.push({
            anno: initialYear + tariffe.value[i].prg,
            inv: tariffe.value[i].invalidity_coverage_evolution,
            cov: tariffe.value[i].coverage_evolution,
          });
        }
        tableCoperture.value = tableCoperture.value.sort((a, b) =>
          a.anno > b.anno ? 1 : b.anno > a.anno ? -1 : 0
        );
      }

      isLoading.value = false;
    });

    const goToHome = function () {
      router.push("/");
    };

    return {
      isLoading,
      tableCoperture,
      toCurrency,
      policy_number,
      goToHome,
      durata,
    };
  },
};
</script>
<style></style>
