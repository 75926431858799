<template>
  <base-nav
    data-v-step="1"
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'bg-success navbar-dark': type === 'default' }"
  >
    <div
      class="text-center text-muted"
      @click="goToHome"
      style="cursor: pointer"
    >
      <img style="width: 6em" src="img/fss/lfss.png" alt="logo" />
    </div>
    <div
      class="navbar-nav align-items-center ml-md-auto"
      style="cursor: pointer"
    >
      <h1 v-if="selectedAgency">Agenzia: {{ selectedAgency.description }}</h1>
    </div>
    <ul class="navbar-nav align-items-center ml-md-auto"></ul>
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        v-if="showNotification"
        class="nav-item"
        tag="li"
        data-v-step="2"
        title-classes="nav-link"
        title-tag="a"
        icon="ni ni-bell-55"
        menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
      >
        <!-- Dropdown header -->
        <div class="px-3 py-3">
          <h6 class="text-sm text-muted m-0">
            You have <strong class="text-primary">13</strong> notifications.
          </h6>
        </div>
        <!-- List group -->
        <div class="list-group list-group-flush">
          <a href="#!" class="list-group-item list-group-item-action">
            <div class="row align-items-center">
              <div class="col-auto">
                <!-- Avatar -->
                <img
                  alt="Image placeholder"
                  src="{{imageURL}}"
                  class="avatar rounded-circle"
                />
              </div>
              <div class="col ml--2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm">John Snow</h4>
                  </div>
                  <div class="text-right text-muted">
                    <small>2 hrs ago</small>
                  </div>
                </div>
                <p class="text-sm mb-0">
                  Let's meet at Starbucks at 11:30. Wdyt?
                </p>
              </div>
            </div>
          </a>
          <a href="#!" class="list-group-item list-group-item-action">
            <div class="row align-items-center">
              <div class="col-auto">
                <!-- Avatar -->
                <img
                  alt="Image placeholder"
                  src="{{imageURL}}"
                  class="avatar rounded-circle"
                />
              </div>
              <div class="col ml--2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm">John Snow</h4>
                  </div>
                  <div class="text-right text-muted">
                    <small>3 hrs ago</small>
                  </div>
                </div>
                <p class="text-sm mb-0">
                  A new issue has been reported for Argon.
                </p>
              </div>
            </div>
          </a>
          <a href="#!" class="list-group-item list-group-item-action">
            <div class="row align-items-center">
              <div class="col-auto">
                <!-- Avatar -->
                <img
                  alt="Image placeholder"
                  src="img/theme/team-3.jpg"
                  class="avatar rounded-circle"
                />
              </div>
              <div class="col ml--2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm">John Snow</h4>
                  </div>
                  <div class="text-right text-muted">
                    <small>5 hrs ago</small>
                  </div>
                </div>
                <p class="text-sm mb-0">Your posts have been liked a lot.</p>
              </div>
            </div>
          </a>
          <a href="#!" class="list-group-item list-group-item-action">
            <div class="row align-items-center">
              <div class="col-auto">
                <!-- Avatar -->
                <img
                  alt="Image placeholder"
                  src="img/theme/team-4.jpg"
                  class="avatar rounded-circle"
                />
              </div>
              <div class="col ml--2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm">John Snow</h4>
                  </div>
                  <div class="text-right text-muted">
                    <small>2 hrs ago</small>
                  </div>
                </div>
                <p class="text-sm mb-0">
                  Let's meet at Starbucks at 11:30. Wdyt?
                </p>
              </div>
            </div>
          </a>
          <a href="#!" class="list-group-item list-group-item-action">
            <div class="row align-items-center">
              <div class="col-auto">
                <!-- Avatar -->
                <img
                  alt="Image placeholder"
                  src="img/theme/team-5.jpg"
                  class="avatar rounded-circle"
                />
              </div>
              <div class="col ml--2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm">John Snow</h4>
                  </div>
                  <div class="text-right text-muted">
                    <small>3 hrs ago</small>
                  </div>
                </div>
                <p class="text-sm mb-0">
                  A new issue has been reported for Argon.
                </p>
              </div>
            </div>
          </a>
        </div>
        <!-- View all -->
        <a
          href="#!"
          class="dropdown-item text-center text-primary font-weight-bold py-3"
          >View all</a
        >
      </base-dropdown>
      <base-dropdown
        :disable-click-outside="disableClickOutside"
        :is-open="isOpenMenus"
        data-v-step="3"
        menu-classes="dropdown-menu-lg dropdown-menu-dark bg-default dropdown-menu-right"
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link"
        icon="ni ni-2x ni-building"
      >
        <div
          @click="changeAgenzia(agenzia)"
          class="row shortcuts px-4"
          v-for="(agenzia, index) in agenzie"
          :value="agenzia.value"
          v-bind:key="index"
        >
          <a class="col-12 shortcut-item text-left" data-v-step="5">
            <small>{{ agenzia.description }}</small>
          </a>
        </div>
      </base-dropdown>
      <base-dropdown
        :disable-click-outside="disableClickOutside"
        :is-open="isOpenMenus"
        data-v-step="3"
        menu-classes="dropdown-menu-lg dropdown-menu-dark bg-default dropdown-menu-right"
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link"
        icon="ni ni-2x ni-ungroup"
      >
        <div class="row shortcuts px-4">
          <a
            @click="goToPrivacyBSS()"
            class="col-4 shortcut-item"
            data-v-step="5"
          >
            <span class="shortcut-media avatar rounded-circle bg-gradient-red">
              <i class="ni ni-glasses-2"></i>
            </span>
            <small>Privacy Broker Senza Sorprese</small>
          </a>
          <a
            @click="goToPrivacySQU()"
            v-if="haveProduct(11)"
            class="col-4 shortcut-item"
          >
            <span class="shortcut-media avatar rounded-circle bg-gradient-red">
              <i class="ni ni-glasses-2"></i>
            </span>
            <small>Privacy Squarelife Insurance AG</small>
          </a>
          <a
            @click="goToPrivacyAgenzia()"
            v-if="haveprivacyagenzia"
            class="col-4 shortcut-item"
          >
            <span class="shortcut-media avatar rounded-circle bg-gradient-red">
              <i class="ni ni-glasses-2"></i>
            </span>
            <small>Privacy di Agenzia</small>
          </a>
          <!--   <a
               @click="goToPrivacyBC()"
               v-if="haveProduct(10)"
               class="col-4 shortcut-item"
             >
               <span class="shortcut-media avatar rounded-circle bg-gradient-red">
                 <i class="ni ni-glasses-2"></i>
               </span>
               <small>Privacy Business Card Group</small>
             </a>
           <a href="#!" class="col-4 shortcut-item">
               <span
                 class="shortcut-media avatar rounded-circle bg-gradient-green"
               >
                 <i class="ni ni-books"></i>
               </span>
               <small>Reports</small>
             </a>
             <a href="#!" class="col-4 shortcut-item">
               <span
                 class="shortcut-media avatar rounded-circle bg-gradient-purple"
               >
                 <i class="ni ni-pin-3"></i>
               </span>
               <small>Maps</small>
             </a>
             <a href="#!" class="col-4 shortcut-item">
               <span
                 class="shortcut-media avatar rounded-circle bg-gradient-yellow"
               >
                 <i class="ni ni-basket"></i>
               </span>
               <small>Shop</small>
             </a>-->
        </div>
      </base-dropdown>
    </ul>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        data-v-step="4"
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <template v-slot:title-container>
          <a href="#" class="nav-link pr-0" @click.prevent>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img alt="Image placeholder" :src="imageURL" />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold"
                  >{{ contact.cognome }} {{ contact.nome }}</span
                >
              </div>
            </div>
          </a>
        </template>

        <div class="dropdown-header noti-title">
          <h6 class="text-overflow m-0">Benvenuto !</h6>
        </div>
        <a @click="goToCambioPassword()" class="dropdown-item">
          <i class="ni ni-single-02"></i>
          <span>Cambio Password</span>
        </a>
        <a @click="goToFotoProfilo()" class="dropdown-item">
          <i class="ni ni-camera-compact"></i>
          <span>Foto Profilo</span>
        </a>
        <a @click="goToIndirizzo()" class="dropdown-item">
          <i class="ni ni-map-big"></i>
          <span>Cambio Indirizzo</span>
        </a>
        <a @click="goToSupport()" class="dropdown-item">
          <i class="ni ni-support-16"></i>
          <span>Il tuo Agente</span>
        </a>
        <div class="dropdown-divider"></div>
        <a @click="goToLogout()" class="dropdown-item">
          <i class="ni ni-user-run"></i>
          <span>Logout</span>
        </a>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import BaseNav from "@/components/Navbar/BaseNav";
import { useStore } from "vuex";
import { ref, computed } from "vue";
import { isUndefined } from "lodash";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import apiCall from "@/utils/api";

export default {
  components: {
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
    disableClickOutside: {
      type: Boolean,
      default: false, // default|light
      description: "Disable click outside menù",
    },
    isOpenMenus: {
      type: Boolean,
      default: false, // default|light
      description: "Open Menu",
    },
    showNotification: {
      type: Boolean,
      default: false, // default|light
      description: "Open Menu",
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  setup: function () {
    const store = useStore();
    const router = useRouter();

    onMounted(async () => {
      let resp = await apiCall({
        url: "/private/user/have-privacy-agenzia",
        data: {},
        method: "GET",
      });

      haveprivacyagenzia.value = resp.esito;
    });
    const contact = computed(() => store.state.user.contact);

    const imageURL = computed(() => {
      if (store.state.user.contact.photo) {
        return store.state.user.contact.photo;
      }
      return "img/fss/user.png";
    });
    let activeNotifications = ref(false);
    let showMenu = ref(false);
    let haveprivacyagenzia = ref(false);
    const agenzie = computed(() => store.state.user.agenzie);
    const selectedAgency = computed(() => store.state.user.selectedAgency);

    const haveProduct = function (idprodotto) {
      const found = store.state.user.prodotti.find(
        (element) => element.idprodotto === idprodotto
      );
      if (!isUndefined(found)) {
        return found.haveProd;
      }
      return false;
    };

    const capitalizeFirstLetter = function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
    const toggleNotificationDropDown = function () {
      activeNotifications.value = !activeNotifications.value;
    };

    const closeDropDown = function () {
      activeNotifications.value = false;
    };

    const goToPrivacyBSS = function () {
      router.push("/privacy-bss");
    };

    const goToPrivacyBC = function () {
      router.push("/privacy-bc");
    };

    const goToPrivacySQU = function () {
      router.push("/privacy-squ");
    };

    const goToPrivacyAgenzia = function () {
      router.push("/privacy-agenzia");
    };

    const goToSupport = function () {
      router.push("/agente");
    };

    const goToIndirizzo = function () {
      router.push("/indirizzo");
    };

    const goToFotoProfilo = function () {
      router.push("/foto-profilo");
    };
    const goToCambioPassword = function () {
      router.push("/cambio-password");
    };
    const goToHome = function () {
      router.push("/home");
    };

    const changeAgenzia = function (agenzia) {
      store.dispatch("user/changeagenzia", agenzia, { root: true });
      router.push("/home");
    };
    const goToLogout = function () {
      store.dispatch("auth/logout", null, { root: true });
      router.push("/login");
    };

    return {
      contact,
      activeNotifications,
      showMenu,
      imageURL,
      goToLogout,
      goToFotoProfilo,
      goToIndirizzo,
      goToSupport,
      goToCambioPassword,
      goToPrivacyBSS,
      goToPrivacyBC,
      goToPrivacySQU,
      haveProduct,
      capitalizeFirstLetter,
      toggleNotificationDropDown,
      closeDropDown,
      goToHome,
      goToPrivacyAgenzia,
      haveprivacyagenzia,
      agenzie,
      selectedAgency,
      changeAgenzia,
    };
  },
};
</script>
