<template>
  <div class="wrapper">
    <div class="main-content">
      <home-navbar
        :type="'light'"
        :disable-click-outside="disableClickOutside"
        :is-open-menus="isOpenMenus"
      ></home-navbar>

      <div
        @click="$sidebar.displaySidebar(true)"
        style="background-color: #172b4d"
      >
        <router-view></router-view>
      </div>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import HomeNavbar from "./HomeNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { useStore } from "vuex";
import { ref, onMounted, getCurrentInstance } from "vue";

export default {
  name: "home-layout",
  components: {
    HomeNavbar,
    ContentFooter,
  },
  setup() {
    const store = useStore();

    let disableClickOutside = ref(false);
    let isOpenMenus = ref(false);
    let contact = ref();
    let steps = ref();
    steps.value = [
      {
        target: '[data-v-step="1"]', // We're using document.querySelector() under the hood
        header: {
          title: "Get Started",
        },
        content: `Discover <strong>Vue Tour</strong>!`,
      },
      {
        target: '[data-v-step="2"]',
        content: "An awesome plugin made with Vue.js!",
      },
      {
        target: '[data-v-step="3"]',
        content:
          "Try it, you'll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.",
        params: {
          placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
        },
      },
      {
        target: '[data-v-step="4"]',
        content:
          "Try it, you'll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.",
        params: {
          placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
        },
      },
      {
        target: '[data-v-step="5"]',
        content:
          "Try it, you'll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.",
        params: {
          placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
        },
        before: () => showMenu(),
      },
      {
        target: '[data-v-step="5"]',
        content:
          "Try it, you'll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.",
        params: {
          placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
        },
        before: () => hideMenu(),
      },
    ];

    store.watch(
      (state) => state.auth.contact,
      (contact) => {
        contact.value = contact;
      }
    );
    onMounted(() => {
      const internalInstance = getCurrentInstance();
      const $tours = internalInstance.appContext.config.globalProperties.$tours;
      if ($tours) {
        if ($tours["myTour"]) {
          $tours["myTour"].start();
        }
      }
    });

    const showMenu = function () {
      disableClickOutside.value = true;
      isOpenMenus.value = true;
    };

    const hideMenu = function () {
      disableClickOutside.value = false;
      isOpenMenus.value = false;
    };
    return {
      contact,
      steps,
      disableClickOutside,
      isOpenMenus,
      showMenu,
      hideMenu,
    };
  },
};
</script>
<style lang="scss"></style>
