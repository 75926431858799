import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store";

import HomeLayout from "@/views/Layout/HomeLayout";
import AuthLayout from "@/views/Pages/AuthLayout";

// Dashboard page
import Charts from "../views/Charts.vue";
import Calendar from "../views/Calendar/Calendar.vue";

import Home from "@/views/Pages/Home";
import DocumentiCliniciPage from "@/views/Pages/DocumentiCliniciPage";
import FabbisogniPage from "@/views/Pages/FabbisogniPage";
import CoperturePage from "@/views/Pages/CoperturePage";
import BeneficiariPage from "@/views/Pages/BeneficiariPage";
import PagamentoPage from "@/views/Pages/PagamentoPage";
import ChangePasswordPage from "@/views/Pages/ChangePasswordPage";
import ChangeFotoPage from "@/views/Pages/ChangeFotoPage";
import DocumentoIdentitaPage from "@/views/Pages/DocumentoIdentitaPage";
import IndirizzoPage from "@/views/Pages/IndirizzoPage";
import PrivacyBssPage from "@/views/Pages/PrivacyBssPage";
import PrivacySqPage from "@/views/Pages/PrivacySqPage";
import PrivacyBcPage from "@/views/Pages/PrivacyBcPage";
import StoricoDiagnosiPage from "@/views/Pages/StoricoDiagnosiPage";
import StoricoDiagnosiFamigliaPage from "@/views/Pages/StoricoDiagnosiFamigliaPage";
import StoricoCarPage from "@/views/Pages/StoricoCarPage";
import StoricoPagamentiPage from "@/views/Pages/StoricoPagamentiPage";
import PagamentoAssPage from "@/views/Pages/PagamentoAssPage";
import AgentePage from "@/views/Pages/AgentePage";
import RicordaPassword from "@/views/Pages/RicordaPassword";
import OpenCasePage from "@/views/Pages/OpenCasePage";
import StoricoAssistenzaPage from "@/views/Pages/StoricoAssistenzaPage";
import Login from "@/views/Pages/Login";
import DocumentiPage from "@/views/Pages/DocumentiPage";
import PrivacyAgenziaPage from "@/views/Pages/PrivacyAgenziaPage";

/*let componentsMenu = {
  path: "/components",
  component: HomeLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
    },
    {
      path: "cards",
      name: "Cards",
      components: { default: Cards },
    },
    {
      path: "grid-system",
      name: "Grid",
      components: { default: Grid },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
    },
  ],
};

let tablesMenu = {
  path: "/tables",
  component: DashboardLayout,
  redirect: "/tables/regular",
  name: "Tables menu",
  children: [
    {
      path: "regular",
      name: "Tables",
      components: { default: RegularTables },
    },
    {
      path: "sortable",
      name: "Sortable",
      components: { default: SortableTables },
    },
    {
      path: "paginated",
      name: "Paginated",
      components: { default: PaginatedTables },
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  redirect: "/maps/google",
  name: "Maps",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps },
    },
    {
      path: "vector",
      name: "Vector Map",
      components: { default: VectorMaps },
    },
  ],
};

let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/elements",
  name: "Forms",
  children: [
    {
      path: "elements",
      name: "Forms elements",
      components: { default: FormElements },
    },
    {
      path: "components",
      name: "Forms Components",
      components: { default: FormComponents },
    },
    {
      path: "validation",
      name: "Forms Validation",
      components: { default: FormValidation },
    },
  ],
};*/

const routes = [
  {
    path: "/",
    redirect: "/home",
    component: HomeLayout,
    name: "Home",
    children: [
      {
        path: "/home",
        name: "Home",
        components: { default: Home },
      },
      {
        path: "/documenti/:id",
        name: "Documenti contrattuali",
        components: { default: DocumentiPage },
        meta: {
          navbarType: "light",
        },
      },
      {
        path: "/coperture/:id",
        name: "Coperture",
        components: { default: CoperturePage },
        meta: {
          navbarType: "light",
        },
      },
      {
        path: "/fabbisogni/:id",
        name: "Fabbisogni",
        components: { default: FabbisogniPage },
        meta: {
          navbarType: "light",
        },
      },
      {
        path: "/beneficiari/:id",
        name: "Beneficiari",
        components: { default: BeneficiariPage },
        meta: {
          navbarType: "light",
        },
      },
      {
        path: "/metodopagamento/:id",
        name: "Metodi Pagamento",
        components: { default: PagamentoPage },
        meta: {
          navbarType: "light",
        },
      },
      {
        path: "/upload-esami/:id",
        name: "Upload Esami",
        components: { default: DocumentiCliniciPage },
      },
      {
        path: "/profilo",
        name: "Profilo",
        components: { default: ChangePasswordPage },
      },
      {
        path: "/storico-diagnosi",
        name: "Storico Diagnosi",
        components: { default: StoricoDiagnosiPage },
      },
      {
        path: "/storico-diagnosi-famiglia",
        name: "Storico Diagnosi Famiglia",
        components: { default: StoricoDiagnosiFamigliaPage },
      },
      {
        path: "/car",
        name: "Storico CAR",
        components: { default: StoricoCarPage },
      },
      {
        path: "/assistenza-senza-sorprese/pagamenti",
        name: "Storico Pagamenti",
        components: { default: StoricoPagamentiPage },
      },
      {
        path: "/assistenza-senza-sorprese/cambia-pagamento",
        name: "Modifica Pagamento",
        components: { default: PagamentoAssPage },
      },
      {
        path: "/agente",
        name: "Agente",
        components: { default: AgentePage },
      },
      {
        path: "/foto-profilo",
        name: "Foto Profilo",
        components: { default: ChangeFotoPage },
      },
      {
        path: "/cambio-password",
        name: "Cambio Password",
        components: { default: ChangePasswordPage },
      },
      {
        path: "/documento-identita",
        name: "Documento Identità",
        components: { default: DocumentoIdentitaPage },
      },
      {
        path: "/indirizzo",
        name: "Indirizzo",
        components: { default: IndirizzoPage },
      },
      {
        path: "/privacy-bss",
        name: "Privacy Broker Senza Sorprese",
        components: { default: PrivacyBssPage },
      },
      {
        path: "/privacy-agenzia",
        name: "Privacy Agenzia",
        components: { default: PrivacyAgenziaPage },
      },
      {
        path: "/privacy-bc",
        name: "Privacy Business Card Group",
        components: { default: PrivacyBcPage },
      },
      {
        path: "/assistenza-senza-sorprese/open-case",
        name: "Apri Richiesta Assistenza",
        components: { default: OpenCasePage },
      },
      {
        path: "/assistenza-senza-sorprese/lista-richieste",
        name: "Lista Richiesta Assistenza",
        components: { default: StoricoAssistenzaPage },
      },
      {
        path: "/privacy-squ",
        name: "Privacy Squarelife Insurance AG",
        components: { default: PrivacySqPage },
      },
      {
        path: "/charts",
        name: "Charts",
        components: { default: Charts },
      },
      {
        path: "/calendar",
        name: "Calendar",
        components: { default: Calendar },
      },
    ],
  },
  {
    path: "/",
    redirect: "/",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        components: { default: Login },
      },
      {
        path: "/forgot",
        name: "Ricorda Password",
        components: { default: RicordaPassword },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

/*
 * Guard
 */

router.beforeResolve((to, from, next) => {
  store
    .dispatch("portal/loadingend")
    .then(() => {})
    .catch(() => {});
  next();
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {});

router.beforeEach((to, from, next) => {
  store
    .dispatch("portal/loadingstart")
    .then(() => {})
    .catch(() => {});
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login", "/forgot"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.getters["auth/isAuthenticated"];

  if (authRequired && !loggedIn) {
    return next("/login");
  }
  /*
  let token = localStorage.getItem("user-token");

  if (token) {
    axios.defaults.headers.post["Authorization"] = "Bearer " + token;
  }*/

  if (loggedIn) {
    const contact = store.getters["user/getProfile"];
    if (Object.keys(contact).length === 0) {
      store
        .dispatch("user/load")
        .then(() => {
          next();
        })
        .catch(() => {
          return next("/login");
        });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
