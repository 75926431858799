<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">il tuo agente</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div class="col-md-12 pl-md-0">
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>
          <br />
          <div class="row">
            <div class="col-md-12 pl-md-0">
              <section class="text-center" v-if="agente">
                <card class="card-blog">
                  <div
                    class="fileinput fileinput-new text-center"
                    data-provides="fileinput"
                  >
                    <div class="fileinput-new thumbnail img-circle">
                      <img :src="imageURL" alt="preview" />
                    </div>
                  </div>
                  <h3 class="title mt-4">
                    {{ agente.username }}
                  </h3>
                  <div v-if="agente.email">
                    email:
                    <a :href="'mailto:' + agente.email">{{ agente.email }}</a
                    ><br />
                  </div>
                  <div v-if="agente.cellulare">
                    cellulare:
                    <a :href="'cell:' + agente.cellulare">{{
                      agente.cellulare
                    }}</a>
                    <br />
                  </div>
                  <div v-if="agente.numerorui">
                    RUI:
                    {{ agente.numerorui }}
                    <br />
                  </div>
                  <br />
                  <div v-if="agente.indirizzo">
                    indirizzo:
                    {{ agente.indirizzo }}
                    <br />
                    <br />
                    <div class="mapouter">
                      <div class="gmap_canvas">
                        <iframe
                          width="600"
                          height="500"
                          id="gmap_canvas"
                          src="https://maps.google.com/maps?q={{agente.indirizzo}}&t=&z=13&ie=UTF8&iwloc=&output=embed"
                          frameborder="0"
                          scrolling="no"
                          marginheight="0"
                          marginwidth="0"
                        ></iframe
                        ><br />
                      </div>
                    </div>
                    <br />
                    <br />
                  </div>
                </card>
              </section>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { formatDate } from "@/filters/formatDate";
export default {
  name: "agente-page",
  components: {
    Loading,
  },
  setup: function () {
    const router = useRouter();
    const isLoading = ref(false);

    const agente = ref(null);
    const imageURL = ref(null);

    onMounted(async () => {
      isLoading.value = true;
      let resp = await apiCall({
        url: "/private/get-agente",
        data: {},
        method: "GET",
      });

      agente.value = resp;
      if (agente.value.photo) {
        imageURL.value = agente.value.photo;
      } else {
        if (agente.value.sesso === "F") {
          imageURL.value = "img/girl.png";
        } else {
          imageURL.value = "img/boy.png";
        }
      }
      isLoading.value = false;
    });

    const goToHome = function () {
      router.push("/");
    };

    return {
      agente,
      isLoading,
      imageURL,
      goToHome,
      formatDate,
    };
  },
};
</script>
<style>
.mapouter {
}
</style>
<style>
.gmap_canvas {
}
</style>
