<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">
                Privacy Business Card Group
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div class="col-md-12 pl-md-0">
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>
          <br />
          <section class="text-center">
            <div>
              <hr class="line-primary" />
              <br />
              <div v-if="!showInformativa">
                <base-button
                  nativeType="submit"
                  type="primary"
                  size="sm"
                  class="mt-4 mb-5"
                  @click="showInformativa = true"
                  >Mostra Informativa completa</base-button
                >
              </div>
              <div v-if="showInformativa">
                <p>
                  <base-button
                    nativeType="submit"
                    type="primary"
                    size="sm"
                    class="mt-4 mb-5"
                    @click="showInformativa = false"
                    >Nascondi Informativa completa</base-button
                  >
                </p>
                <p>
                  La presente informativa è resa da BUSINESS CARD GROUP S.R.L.
                  in conformità a quanto previsto dal DECRETO LEGISLATIVO 10
                  agosto 2018, n. 101.
                </p>
                <p><strong>1. Titolare del trattamento</strong></p>
                <p>
                  Titolare del trattamento dei dati personali è BUSINESS CARD
                  GROUP S.R.L.,<br />
                  con sede legale in Viale dell'Industria n. 23 – 35129
                  Padova,<br />
                  tel. 049 7993710,<br />
                  fax 049 8076542,<br />
                  mail:
                  <a href="mailto:info@businesscardgroup.it"
                    ><span style="color: windowtext"
                      >info@businesscardgroup.it</span
                    ></a
                  >,<br />
                  pec:
                  <a href="mailto:businesscardgroup@pec.it"
                    ><span style="color: windowtext"
                      >businesscardgroup@pec.it.</span
                    ></a
                  >
                </p>
                <p>
                  <strong
                    >2. Finalit&agrave; e basi giuridiche dell'elaborazione
                    dati</strong
                  >
                </p>
                <p>
                  I dati personali di cui all'allegato contratto sono raccolti e
                  trattati:<br />
                  per l’espletamento di attività connesse alla stipulazione ed
                  esecuzione del suddetto rapporto contrattuale, ivi compresi i
                  servizi previsti dalla Business Card; il conferimento di tali
                  dati è obbligatorio ai fini della stipulazione e
                  dell'esecuzione del suddetto contratto, che altrimenti non
                  potrà essere concluso. Base giuridica di detto trattamento è
                  il contratto stesso.<br />
                  a) per finalità di fatturazione in relazione agli adempimenti
                  fiscali imposti dalla Legge, che costituisce la base giuridica
                  di detta finalità di trattamento; il conferimento di tali dati
                  è obbligatorio ai fini della stipulazione e dell'esecuzione
                  del suddetto contratto, che, altrimenti non potrà essere
                  concluso. Base giuridica è il menzionato obbligo di Legge.<br />
                  b) per l'invio di comunicazioni, informazioni, offerte ed
                  iniziative commerciali legate alla Business Card e alle
                  attività di BUSINESS CARD GROUP S.r.l(. marketing d i r e t t
                  oil )c ;o nferimento di tali dati non è obbligatorio ai fini
                  della conclusione e dell'esecuzione del suddetto contratto.
                  Base giuridica di detto trattamento è la prestazione di
                  apposito consenso da parte dell'interessato/acquirente di
                  Business Card.<br />
                  c) per l'invio di comunicazioni, informazioni, offerte ed
                  iniziative commerciali legate alla Business Card e alle
                  attività di BUSINESS CARD GROUP S.r.l(. marketing d i r e t t
                  oil )c ;o nferimento di tali dati non è obbligatorio ai fini
                  della conclusione e dell'esecuzione del suddetto contratto.
                  Base giuridica di detto trattamento è la prestazione di
                  apposito consenso da parte dell'interessato/acquirente di
                  Business Card.<br />
                </p>
                <p><strong>3. Modalità di trattamento</strong></p>
                <p>
                  I dati forniti saranno trattati per le finalità esposte sia in
                  forma cartacea che con strumenti informatici e telematici.
                </p>
                <p>
                  <strong
                    >4. Diffusione, comunicazione e soggetti che accedono ai
                    dati personali</strong
                  >
                </p>
                <p>
                  Ferma la possibilità di consultazione prevista per Legge da
                  parte delle competenti Autorità, i dati personali potranno
                  essere comunicati a soggetti interni, autorizzati ed
                  appositamente formati, che procederanno al trattamento;
                  potranno altresì, ai fini dell'esecuzione del contratto,
                  essere comunicati a consulenti tecnici, nominati Responsabili
                  di trattamento dati ai sensi del GDPR e ad avvocati,
                  commercialisti, medici, in qualità di autonomi titolari di
                  trattamento.
                </p>
                <p>
                  <strong
                    >5. Periodi di conservazione dei dati personali o criteri
                    per determinare tali periodi</strong
                  >
                </p>
                <p>
                  I dati personali forniti per la finalità di cui ai punti 2.a)
                  e 2.b) saranno conservati per 10 anni, a decorrere dalla data
                  di estinzione del rapporto contrattuale, per ragioni
                  amministrative e fiscali.<br />
                  I dati personali forniti per la finalità di cui al punto 2.c)
                  saranno conservati, a decorrere dalla data di sottoscrizione
                  del contrattuale, per un periodo di 10 anni.<br />
                </p>
                <p><strong>6. Diritti dell'interessato</strong></p>
                <p>
                  Si ricorda che l’interessato ha diritto di esercitare i
                  seguenti diritti: a) chiedere al titolare del trattamento
                  l’accesso ai dati personali e la rettifica o la cancellazione
                  degli stessi o la limitazione del trattamento che lo
                  riguardano o di opporsi al loro trattamento; b) revocare il
                  consenso, ove prestato; c) chiedere al titolare la portabilità
                  dei dati, secondo quanto previsto dall’art. 20 del GDPR; d)
                  proporre reclamo all’autorità di controllo competente (nello
                  Stato membro ove si è verificata la presunta violazione,
                  oppure in cui l’interessato risiede abitualmente o lavora).
                  Per esercitare i suddetti diritti, l'interessato dovrà
                  rivolgere apposita richiesta scritta indirizzata a BUSINESSC
                  ARD GROUP S.r.l. ai recapiti di cui al punto 1.
                </p>
                <p>
                  <strong
                    >7. Eventuale esistenza di un processo decisionale
                    automatizzato, compresa la profilazione</strong
                  >
                </p>
                <p>
                  Si informa che è presente un processo decisionale
                  automatizzato e sono previste attività di profilazione.
                </p>
              </div>
              <p>
                <strong>CONSENSO AL TRATTAMENTO DEI DATI PERSONALI</strong>
              </p>
              <form
                role="form"
                @submit.prevent="onSubmit"
                v-for="privacySingola in privacymaz"
                :key="privacySingola.idrssprivacy"
              >
                <div class="row mb-3">
                  <div class="col-12 align-items-left justify-content">
                    <span>{{ privacySingola.domanda }}</span>
                  </div>
                  <div
                    class="col-12 form-group align-items-center justify-content"
                    style="text-align: center; vertical-align: middle"
                  >
                    <span class="mb-2 mt-2">Acconsento:</span>
                    <br />
                    <base-switch
                      :value="checkPrivacyValue(privacySingola)"
                      v-if="privacySingola.can_change"
                      type="primary"
                      offText="No"
                      onText="Si"
                      @input="
                        changePrivacy($event, privacySingola.idrssprivacy)
                      "
                    ></base-switch>
                    <label
                      class="custom-toggle custom-toggle-danger"
                      v-if="!privacySingola.can_change"
                    >
                      <input
                        type="checkbox"
                        :checked="checkPrivacyValue(privacySingola)"
                        disabled
                      />
                      <span
                        data-label-off="No"
                        data-label-on="Si"
                        class="custom-toggle-slider rounded-circle"
                      >
                      </span>
                    </label>
                    <base-alert type="danger" v-if="!privacySingola.can_change">
                      <strong
                        >Il consenso è obbligatorio e non può essere
                        modificato</strong
                      >
                    </base-alert>
                  </div>
                </div>
                <hr />
              </form>
            </div>
          </section>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import "flatpickr/dist/flatpickr.css";
import apiCall from "@/utils/api";
import Loading from "vue-loading-overlay";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { formatDate } from "@/filters/formatDate";
import BaseAlert from "@/components/BaseAlert";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";
export default {
  name: "privacy-bc",
  components: {
    Loading,
    BaseAlert,
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(false);

    const showInformativa = ref(false);
    const privacymaz = ref({});
    const rssPolizzaPrivacy = ref({});

    const onSubmit = function () {
      // store.dispatch("auth/login", values);
      // formError.value = false;
    };

    onMounted(async () => {
      isLoading.value = true;
      let resp = await apiCall({
        url: "/private/privacy/maz",
        data: {},
        method: "GET",
      });

      privacymaz.value = resp.privacy;

      rssPolizzaPrivacy.value = resp.userprivacy;
      isLoading.value = false;
    });

    const goToHome = function () {
      router.push("/");
    };

    const checkPrivacyValue = function (privacySingola) {
      const element = rssPolizzaPrivacy.value.find(
        (element) => element.idrssprivacy == privacySingola.idrssprivacy
      );
      if (element !== undefined) {
        return element.risposta;
      } else {
        return privacySingola.default_response;
      }
    };

    const changePrivacy = async function ($event, key) {
      if (typeof $event === "boolean") {
        const element = rssPolizzaPrivacy.value.find(
          (element) => element.idrssprivacy == key
        );
        isLoading.value = true;
        await apiCall({
          url: "/private/setPrivacy",
          data: {
            valore: $event,
            idrssprivacy: key,
            idrsspolizza: element.idrsspolizza,
            idrsspolizzaprivacy: element.idrsspolizzaprivacy,
          },
          method: "POST",
        });
        isLoading.value = false;
      }
    };

    return {
      rssPolizzaPrivacy,
      checkPrivacyValue,
      changePrivacy,
      showInformativa,
      isLoading,
      privacymaz,
      goToHome,
      formatDate,
      onSubmit,
    };
  },
};
</script>
<style>
.account-settings .nav {
  text-align: left;
}

.account-settings .nav .nav-item {
  padding: 1rem 0;
}

.account-settings .nav .nav-item:not(:last-child) {
  border-bottom: 1px solid #5e72e4;
}

/* Checkmark & Strikethrough --------- */

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 100%;
}

.checkmark_container {
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: -8px;
  background: #2ecc71;
  width: 25px;
  height: 25px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.is_valid {
  color: green;
}

.is_invalid {
  color: red;
}

.checked {
  animation: draw 0.5s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
