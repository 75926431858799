<template>
  <div>
    <base-nav
      v-model="showMenu"
      type="light"
      :transparent="true"
      menu-classes="justify-content-end"
      class="navbar-horizontal navbar-main"
      expand="lg"
    >
    </base-nav>

    <div class="main-content">
      <router-view></router-view>
    </div>

    <footer class="footer">
      <div class="container">
        <div class="row row-grid align-items-center mb-5">
          <div class="col-md-12" style="font-size: 12px">
            <b
              >Reddito Senza Sorprese/Assistenza Senza Sorprese sono un marchio
              di Broker Senza Sorprese srl</b
            >
            - società benefit, registrata in Italia nel Registro delle Imprese
            di Vicenza, P. IVA, num 04290780248 - R.E.A. VI - 393619 - Capitale
            sociale interamente versato € 20.000 - Via Montegrappa 37/C, 36063
            Marostica (VI) telefono:
            <a href="tel:+39 0444 1497459">+39 0444 1497459</a> email:
            <a href="mailto:info@brokersenzasorprese.com"
              >info@brokersenzasorprese.com</a
            >; reclami:
            <a href="mailto:reclami@brokersenzasorprese.com"
              >reclami@brokersenzasorprese.com</a
            >; pec:
            <a href="mailto:brokersenzasorprese@legalmail.it"
              >brokersenzasorprese@legalmail.it</a
            >
            <br />
            <br />
            <b>Broker Senza Sorprese srl</b> - società benefit è soggetta al
            controllo dell’IVASS e iscritta al Registro Unico degli Intermediari
            Assicurativi
            <a href="https://servizi.ivass.it/RuirPubblica/"
              >https://servizi.ivass.it/RuirPubblica/</a
            >
            con codice B000671254 dal 07/12/2020.
            <br />
            <br />
            <b>Assicuratore Facile srl</b> - P.IVA 04019050246 | Capitale
            Sociale € 10.000 | REA: VI 372362 IndirizzoVia Montegrappa 37/C –
            36063 – Marostica (VI) Email :
            <a href="mailto:assicuratorefacile@legalmail.it"
              >assicuratorefacile@legalmail.it</a
            >
          </div>
        </div>
        <div class="row align-items-center justify-content-md-between">
          <div class="col-md-6">
            <div class="copyright">
              &copy; {{ year }}
              <a href="https://satorsoftware.com" target="_blank"
                >Created by Sator Software SRL</a
              >
            </div>
          </div>
          <div class="col-md-6">
            <ul class="nav nav-footer justify-content-end">
              <li class="nav-item">
                <a
                  href="https://redditosenzasorprese.com/"
                  class="nav-link"
                  target="_blank"
                  >Broker Senza Sorprese</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://www.futurosenzasorprese.com/"
                  class="nav-link"
                  target="_blank"
                  >Chi siamo</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://www.iubenda.com/privacy-policy/20087558"
                  class="nav-link"
                  target="_blank"
                  >Privacy Policy</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: "login-page",
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    },
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    setBackgroundColor() {
      document.body.classList.add("bg-default");
    },
    removeBackgroundColor() {
      document.body.classList.remove("bg-default");
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor();
      } else {
        this.removeBackgroundColor();
      }
    },
  },
  beforeUnmount() {
    this.removeBackgroundColor();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.updateBackground();
      },
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}
</style>
