import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  RESEND_PASSWORD,
  REFRESH_TOKEN,
} from "../actions/auth";

import apiCall from "@/utils/api";

const state = {
  token: localStorage.getItem("user-token"),
  status: "",
  hasLoadedOnce: false,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  authStatus: (state) => state.status,
};

const actions = {
  async storelogindate({ commit }, resp) {
    commit(AUTH_SUCCESS, resp);
    localStorage.setItem("user-token", resp.token);
  },
  async login({ commit, dispatch }, { cf, password }) {
    commit(AUTH_REQUEST);
    try {
      let resp = await apiCall({
        url: "/public/login",
        data: { cf: cf.trim(), password: password.trim() },
        method: "POST",
      });

      commit(AUTH_SUCCESS, resp);
      localStorage.setItem("user-token", resp.token);

      let respPrd = await apiCall({ url: "/private/prodotti" });
      dispatch("user/load", { user: resp, prodotti: respPrd }, { root: true });
      /* commit(
        "user/" + USER_SUCCESS,
        { user: resp, prodotti: respPrd },
        { root: true }
      );*/
    } catch (err) {
      commit(AUTH_ERROR, err);
      localStorage.removeItem("user-token");
    }
  },
  logout({ commit }) {
    commit(AUTH_LOGOUT);
    localStorage.removeItem("user-token");
  },
  refreshtoken({ commit }, { newtoken }) {
    commit(REFRESH_TOKEN, newtoken);
    localStorage.setItem("user-token", newtoken);
  },
  // eslint-disable-next-line no-empty-pattern
  remember({ commit }, { cf }) {
    commit(RESEND_PASSWORD);
    apiCall({
      url: "/public/resendPassword",
      data: { cf: cf.trim() },
      method: "POST",
    })
      .then(() => {})
      .catch(() => {});
  },
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = "loading";
  },
  [RESEND_PASSWORD]: () => {},
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.token = resp.token;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: (state) => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = "";
  },
  [REFRESH_TOKEN]: (state, resp) => {
    state.token = resp;
  },
};

export const authStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
