<template>
  <div
    v-bind:class="showCaricato ? 'flip-container flipped' : 'flip-container'"
  >
    <div class="flipper">
      <div class="front">
        <slot name="front">
          <card class="card-frame">
            <h4 class="text-uppercase ls-1 text-primary py-3 mb-2">
              {{ description }}
            </h4>
            <base-alert type="danger" v-if="error">
              <strong>Attenzione!</strong> {{ message }}
            </base-alert>
            <base-progress
              v-if="uploading"
              :value="progress"
              type="defaults"
              label="Upload in corso..."
              showLabel
            ></base-progress>

            <div v-show="!uploading">
              <dropzone-file-upload
                @change="changeFiles"
                @error="changeFiles"
                :options="{
                  mydz: { dictDefaultMessage: 'Trascina i tuoi file qui' },
                  disablePreviews: false,
                  autoProcessQueue: false,
                  acceptedFiles: 'application/pdf',
                  maxFilesize: 10,
                }"
                :initial-files="fileRecords"
                multiple
              ></dropzone-file-upload>
            </div>

            <base-button
              :disabled="!fileRecordsForUpload.length"
              @click.stop="uploadFiles()"
              v-if="!uploading"
              class="btn-block mb-2 mt-2"
              size="sm"
              type="danger"
              >Carica file</base-button
            >
            <base-button
              v-if="!uploading && caricato"
              class="btn-block mb-2 mt-2"
              size="sm"
              @click="setCaricato(true)"
              type="primary"
            >
              Indietro</base-button
            >
          </card>
        </slot>
      </div>
      <div class="back">
        <slot name="back">
          <card class="card-frame">
            <h4 class="text-uppercase ls-1 text-primary py-3 mb-2">
              {{ description }}
            </h4>
            <badge
              type="success"
              rounded
              class="badge-floating badge-lg border-white badge-circle"
              ><i class="ni ni-check-bold"></i
            ></badge>
            <h6 class="text-uppercase ls-1 text-primary py-3 mb-2">
              L'esito risulta caricato, clicca sul pulsante per rieffettuare
              l'upload
            </h6>
            <base-button
              class="btn-block mb-2 mt-2"
              size="sm"
              @click="setCaricato(false)"
              type="danger"
            >
              Ricarica</base-button
            >
          </card>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import apiCall from "@/utils/api";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import BaseAlert from "@/components/BaseAlert";
import BaseProgress from "@/components/BaseProgress";

export default {
  name: "UploadEsamiCard",
  components: {
    DropzoneFileUpload,
    BaseAlert,
    BaseProgress,
  },
  data: function () {
    return {
      file: null,
      fileName: "",
      showCaricato: this.caricato,
      label: "",
      message: "",
      progress: 0,
      uploading: false,
      error: false,
      enableUpload: false,
      fileRecords: [],
      uploadUrl: "",
      uploadHeaders: null,
      fileRecordsForUpload: [], // maintain an upload queue
    };
  },
  props: {
    description: String,
    initiaLabel: String,
    id: String,
    caricato: Boolean,
    idrsspolizzavisite: Number,
    policy_number: String,
    uploaded: Boolean,
  },
  mounted() {
    this.label = this.initiaLabel;
  },
  methods: {
    uploadFiles: async function () {
      this.uploading = true;
      this.message = "";
      this.error = false;

      for (let i = 0; i < this.fileRecordsForUpload.length; i++) {
        let res = await this.onSubmit(i);
        if (res !== "OK") {
          this.fileRecords = [];
          if (this.message === "") {
            this.message +=
              "Alcuni file non sono stati caricati correttamente: " +
              this.fileRecordsForUpload[i].name;
          } else {
            this.message += " " + this.fileRecordsForUpload[i].name;
          }
          this.fileRecords.push(this.fileRecordsForUpload[i]);
        }
      }
      this.uploading = false;
      this.fileRecordsForUpload = [];

      this.file = null;
      this.fileName = "";
      this.label = this.initiaLabel;
      if (this.message === "") {
        this.setCaricato(true);
        this.enableUpload = false;
      } else {
        this.error = true;
      }
    },
    changeFiles: function (fileRecord) {
      this.fileRecordsForUpload = fileRecord;
    },
    setCaricato: function (val) {
      this.showCaricato = val;
    },
    async onSubmit(i) {
      const formData = new FormData();
      formData.append(
        "file",
        this.fileRecordsForUpload[i],
        this.fileRecordsForUpload[i].name
      );
      formData.append("name", this.fileRecordsForUpload[i].name);
      const _ = this;

      try {
        const optObj = {
          headers: {},
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            _.progress = percentCompleted;
          },
        };
        await apiCall({
          url:
            "/private/polizza/uploadreferto/" +
            this.policy_number +
            "/" +
            this.idrsspolizzavisite,
          data: formData,
          method: "POST",
          options: optObj,
        });
        this.progress = 0;
        return "OK";
      } catch (err) {
        this.progress = 0;
        return err.response.data[0].error;
      }
    },
  },
};
</script>

<style type="text/css" scoped>
i.frontFlipBtn,
i.backFlipBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #ffffff;
}
i.backFlipBtn {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.flip-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
}
.flip-container {
  min-height: 120px;
}
.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  position: relative;
}
.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  top: 0;
  left: 0;
  width: 100%;
}
.back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  position: absolute;
}
.flip-container.flipped .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.flip-container.flipped .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.front {
  z-index: 2;
}
</style>
