<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">
                storico richieste assistenza
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div class="col-md-12 pl-md-0">
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>
          <br />
          <div class="table-responsive">
            <table class="table text-left">
              <thead class="thead-light">
                <tr>
                  <th scope="col">Stato</th>
                  <th scope="col">Data inserimento</th>
                  <th scope="col">Data presa in carico</th>
                  <th scope="col">Data chiusura</th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(richiesta, index) in richieste"
                  :key="richiesta.idassistenzasscase"
                >
                  <tr :class="{ even: index % 2 === 0, odd: index % 2 != 0 }">
                    <th scope="row" rowspan="3">
                      <badge v-if="richiesta.stato === 1" type="info" size="lg"
                        >INSERITA</badge
                      >
                      <badge
                        v-if="richiesta.stato === 2"
                        type="warning"
                        size="lg"
                        >PRESA IN CARICO</badge
                      >
                      <badge
                        v-if="richiesta.stato === 3"
                        type="success"
                        size="lg"
                        >CHIUSA</badge
                      >
                      <badge
                        v-if="richiesta.stato === 4"
                        type="danger"
                        size="lg"
                        >RIFIUTATA</badge
                      >
                    </th>
                    <th scope="row">
                      <h4>
                        {{ formatDate(richiesta.data_creazione) }}
                      </h4>
                    </th>
                    <th scope="row">
                      {{ formatDate(richiesta.data_presa_carico) }}
                    </th>
                    <th scope="row">
                      {{ formatDate(richiesta.data_chiusura) }}
                    </th>
                  </tr>
                  <tr :class="{ even: index % 2 === 0, odd: index % 2 != 0 }">
                    <th scope="row">
                      <b>Testo richiesta:</b>
                    </th>
                    <th scope="row" colspan="3">
                      <div
                        style="white-space: normal"
                        v-html="richiesta.descrizione"
                      ></div>
                    </th>
                  </tr>
                  <tr :class="{ even: index % 2 === 0, odd: index % 2 != 0 }">
                    <th scope="row">
                      <b>Note chiusura:</b>
                    </th>
                    <th scope="row" colspan="3">
                      <div
                        style="white-space: normal"
                        v-html="richiesta.note"
                      ></div>
                    </th>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
// Import component
import Loading from "vue-loading-overlay";
import { formatDate, toCurrency } from "@/filters/formatDate";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import Badge from "@/components/Badge";
export default {
  name: "storico-assistenza-page",
  components: {
    Badge,
    Loading,
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(false);

    const richieste = ref(null);

    onMounted(async () => {
      isLoading.value = true;
      let resp = await apiCall({
        url: "/private/assistenza-senza-sorprese/richieste-assistenza",
        data: {},
        method: "GET",
      });

      richieste.value = resp;
      isLoading.value = false;
    });

    const goToHome = function () {
      router.push("/");
    };

    return {
      richieste,
      isLoading,
      formatDate,
      toCurrency,
      goToHome,
    };
  },
};
</script>
<style>
.even {
  background-color: #dcdcdc;
}
.odd {
  background-color: whitesmoke;
}
</style>
