<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">
                Privacy Broker Senza Sorprese
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div class="col-md-12 pl-md-0">
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>
          <br />
          <section class="text-center">
            <div>
              <hr class="line-primary" />
              <br />
              <div v-if="!showInformativa">
                <base-button
                  nativeType="submit"
                  type="primary"
                  size="sm"
                  class="mt-4 mb-5"
                  @click="showInformativa = true"
                  >Mostra Informativa completa</base-button
                >
              </div>
              <div v-if="showInformativa">
                <p>
                  <base-button
                    nativeType="submit"
                    type="primary"
                    size="sm"
                    class="mt-4 mb-5"
                    @click="showInformativa = false"
                    >Nascondi Informativa completa</base-button
                  >
                </p>
                <p>
                  <strong>Informativa sulla tutela dei dati personali </strong>
                </p>
                <p>
                  Ai sensi dell&rsquo;art. 13 del D.lgs. 30 giugno 2003 n. 196 e
                  successive modifiche e dell&rsquo;art. 13 GDPR 679/2016
                  &ndash; &ldquo;Regolamento europeo sulla protezione dei dati
                  personali e in relazione ai dati personali che La riguardano e
                  che formeranno oggetto del trattamento, La informiamo che:
                </p>
                <p>&nbsp;</p>
                <p>
                  BROKER SENZA SORPRESE S.R.L. - SOCIETA' BENEFIT in
                  qualit&agrave; di Titolare del trattamento intende acquisire,
                  anche verbalmente, direttamente o tramite terzi, o gi&agrave;
                  detiene, alcuni Suoi dati, qualificati come personali, il cui
                  trattamento viene effettuato nel rispetto dei diritti e delle
                  libert&agrave; fondamentali, nonch&eacute; della
                  dignit&agrave; dell&rsquo;interessato, con particolare
                  riferimento alla riservatezza, all&rsquo;identit&agrave;
                  personale, al diritto ed alla protezione dei dati personali.
                </p>
                <p>
                  Saranno inoltre trattate anche categorie particolari di dati,
                  che devono essere forniti da Lei in qualit&agrave; di soggetto
                  interessato o da terzi, ad esempio da contraenti di polizze
                  collettive o individuali che La qualificano come assicurato,
                  beneficiario, proprietario dei beni assicurati o danneggiato
                  (come nel caso di polizze di responsabilit&agrave; civile)
                  oppure da banche dati che vengono consultate in fase pre
                  assuntiva, assuntiva o liquidativa).
                </p>
                <p>
                  Ci&ograve; premesso, BROKER SENZA SORPRESE S.R.L. - SOCIETA'
                  BENEFIT La informa riguardo le finalit&agrave; e le
                  modalit&agrave; del trattamento dei dati personali raccolti e
                  il loro ambito di comunicazione e diffusione, oltre alla
                  natura del loro conferimento, premettendo che i trattamenti
                  avvengono nel contesto delle analisi obbligatorie per legge o
                  regolamento, dell&rsquo;instaurazione ed esecuzione dei
                  rapporti commerciali in essere o in divenire e dei rapporti
                  consulenziali da Lei richiesti come previsti da leggi o
                  regolamenti di settore.
                </p>
                <p>
                  <strong
                    >FINALITA&rsquo; DI TRATTAMENTO E BASE GIURIDICA
                  </strong>
                </p>
                <p>
                  Tutti i dati personali e sensibili da Lei conferiti, o
                  gi&agrave; detenuti da BROKER SENZA SORPRESE S.R.L. - SOCIETA'
                  BENEFIT, oppure raccolti presso altri soggetti e presso altre
                  banche dati, la cui consultazione &egrave; prevista per legge
                  o per regolamento, costituiscono oggetto di trattamento.
                  BROKER SENZA SORPRESE S.R.L. - SOCIETA' BENEFIT non dispone di
                  mezzi illeciti per ottenere queste informazioni, che saranno
                  utilizzate:
                </p>
                <p>
                  Tutti i dati personali e sensibili da Lei conferiti, o
                  gi&agrave; detenuti da BROKER SENZA SORPRESE S.R.L. - SOCIETA'
                  BENEFIT, oppure raccolti presso altri soggetti e presso altre
                  banche dati, la cui consultazione &egrave; prevista per legge
                  o per regolamento, costituiscono oggetto di trattamento:
                  BROKER SENZA SORPRESE S.R.L. - SOCIETA' BENEFIT non dispone di
                  mezzi illeciti per ottenere queste informazioni, che saranno
                  utilizzate:
                </p>
                <ol>
                  <li>
                    per la gestione delle attivit&agrave; specifiche di BROKER
                    SENZA SORPRESE S.R.L. - SOCIETA' BENEFIT, in qualit&agrave;
                    di broker assicurativo, e degli adempimenti obbligatori
                    quali:
                    <ul>
                      <li>
                        adempimento degli obblighi previsti da leggi,
                        regolamenti o normative comunitarie, nonch&eacute; da
                        disposizioni impartite da Autorit&agrave; a ci&ograve;
                        legittimate dalla legge o da organi di vigilanza e di
                        controllo, anche del settore assicurativo;
                      </li>
                      <li>
                        erogazione di consulenza e supporto finalizzate alla
                        proposta di prodotti e servizi adeguati alle Sue
                        esigenze e alla stipula di contratti da attivare su Sua
                        iniziativa;
                      </li>
                      <li>
                        mediazione con imprese di assicurazione o
                        riassicurazione svolta nel Suo interesse a seguito di
                        Suo incarico;
                      </li>
                      <li>
                        conclusione, gestione ed esecuzione dell&rsquo;incarico
                        nonch&eacute; delle attivit&agrave; ad esso connesse;
                      </li>
                      <li>
                        prevenzione ed individuazione delle frodi assicurative e
                        relative azioni legali, nonch&eacute; per gli
                        adempimenti previsti dalla normativa antiriciclaggio;
                        gestione delle esigenze di tipo operativo e
                        amministrativo dei contratti conclusi con la nostra
                        assistenza, in relazione alla copertura di rischi
                        assicurativi;
                      </li>
                      <li>
                        gestione, consulenza, supporto e liquidazione in merito
                        alle pratiche di sinistro con le Compagnie di
                        Assicurazioni.
                      </li>
                    </ul>
                  </li>
                  <li>
                    per attivit&agrave; di marketing, di promozione commerciale
                    propria o di terzi e di analisi (per cui saranno trattati
                    esclusivamente i dati personali comuni, previo rilascio di
                    opportuno consenso) quali
                    <ol>
                      <li>
                        informazione e/o promozione commerciale, per illustrare
                        nuove opportunit&agrave; di Suo possibile interesse, a
                        mezzo posta, telefono o mediante comunicazioni
                        elettroniche come e-mail, fax, messaggi Sms o MMS e
                        altri sistemi automatizzati disponibili allo scopo,
                        volte a far conoscere i nuovi servizi e prodotti
                        assicurativi adeguati al Suo profilo di rischio e a
                        migliorare prodotti e servizi offerti, cos&igrave; come
                        imposto agli intermediari dalle vigenti normative, fermo
                        restando il Suo diritto di opposizione, in tutto o in
                        parte, per le suddette finalit&agrave;
                      </li>
                      <li>
                        informazione e/o promozione commerciale, per illustrare
                        nuove opportunit&agrave; di Suo possibile interesse, a
                        mezzo posta, telefono o mediante comunicazioni
                        elettroniche come e-mail, fax, messaggi Sms o MMS e
                        altri sistemi automatizzati disponibili allo scopo,
                        volte a far conoscere i nuovi servizi e prodotti, anche
                        di terzi, di cui BROKER SENZA SORPRESE S.R.L. - SOCIETA'
                        BENEFIT &egrave; autorizzato da leggi, normative o
                        appositi mandati e/o contratti, a curare la
                        commercializzazione;
                      </li>
                      <li>
                        ricerche di mercato ed indagini sulla qualit&agrave; dei
                        servizi e sulla Sua soddisfazione, anche avvalendosi di
                        societ&agrave; specializzate, con l&rsquo;obiettivo di
                        migliorare l&rsquo;offerta di prodotti e servizi;
                      </li>
                      <li>
                        comunicazione dei Suoi dati personali verso soggetti
                        terzi, operanti nel settore indicato nella relativa
                        richiesta di consenso, per finalit&agrave; di
                        informazione e promozione commerciale di prodotti o
                        servizi da parte degli stessi.
                      </li>
                      <li>
                        profilazione volta ad analizzare i Suoi bisogni e le Sue
                        esigenze assicurative per l&rsquo;individuazione, anche
                        attraverso elaborazioni elettroniche, dei possibili
                        prodotti o servizi in linea con le Sue preferenze e i
                        Suoi interessi.
                      </li>
                    </ol>
                  </li>
                </ol>
                <p>
                  I dati oggetto di profilazione, da cui sono rigorosamente
                  esclusi i dati idonei a rivelare lo stato di salute e la vita
                  sessuale, con riferimento a clienti individuabili, potranno
                  essere conservati per finalit&agrave; di profilazione per un
                  periodo non superiore a dodici mesi dalla loro registrazione,
                  salva la reale trasformazione in forma anonima che non
                  permetta, anche indirettamente o collegando altre banche dati,
                  di identificare gli interessati: l'attivit&agrave; di
                  profilazione sar&agrave; svolta utilizzando dati strettamente
                  necessari al perseguimento delle finalit&agrave; dichiarate.
                </p>
                <p>
                  Tutti i dati da Lei conferiti sono trattati, quindi,
                  esclusivamente per adempimenti connessi all'attivit&agrave; di
                  BROKER SENZA SORPRESE S.R.L. - SOCIETA' BENEFIT, le cui basi
                  giuridiche sono rinvenibili nel consenso (es. per il
                  trattamento delle &ldquo;categorie particolari di dati
                  personali&rdquo; o per marketing/profilazione) o
                  nell&rsquo;esecuzione di un contratto di cui Lei &egrave;
                  parte o nell&rsquo;esecuzione di misure precontrattuali
                  adottate su Sua richiesta e/o nell&rsquo;adempiere a obblighi
                  legali ai quali &egrave; soggetto lo scrivente Titolare e/o
                  nel legittimo interesse dello stesso.
                </p>
                <p>
                  <strong
                    >NATURA OBBLIGATORIA E FACOLTATIVA DEL CONFERIMENTO DEI DATI
                    E CONSEGUENZE DI UN EVENTUALE RIFIUTO&nbsp;
                  </strong>
                </p>
                <p>
                  Il conferimento dei dati personali ed il conseguente
                  trattamento da parte da BROKER SENZA SORPRESE S.R.L. -
                  SOCIETA' BENEFIT , per le finalit&agrave; di cui al punto 1,
                  sono necessari per l&rsquo;instaurazione, per la prosecuzione
                  e per la corretta gestione del rapporto tra Titolare ed
                  Interessato, nonch&eacute; strettamente necessari alla
                  gestione ed esecuzione dei rapporti in essere o alla gestione
                  dei sinistri: tale conferimento deve pertanto intendersi come
                  obbligatorio in base a legge, regolamento o normativa
                  comunitaria (si cita a titolo esemplificativo e non
                  limitativo, la normativa antiriciclaggio): l&rsquo;eventuale
                  rifiuto a fornire i dati personali richiesti potr&agrave;
                  causare l&rsquo;impossibilit&agrave;, in tutto o in parte, di
                  perfezionare e di gestire il rapporto consulenziale in essere
                  o in divenire. Il conferimento dei dati personali ed il
                  conseguente trattamento da parte di BROKER SENZA SORPRESE
                  S.R.L. - SOCIETA' BENEFIT per le finalit&agrave; di cui al
                  punto 2 &egrave; facoltativo ed il mancato conferimento, anche
                  parziale, non pregiudicher&agrave; l&rsquo;esecuzione di
                  polizze assicurative eventualmente stipulate, precludendo il
                  solo espletamento delle attivit&agrave; indicate nel punto
                  citato.
                </p>
                <p>
                  <strong>MODALITA&rsquo; DI TRATTAMENTO </strong>
                </p>
                <p>
                  Il trattamento dei dati personali sar&agrave; effettuato sia
                  su supporti cartacei, mediante strumenti manuali, sia con
                  l&rsquo;ausilio di strumenti elettronici mediante idonee
                  procedure informatiche e telematiche, attraverso sistemi e
                  banche dati di propriet&agrave; dello scrivente, atti a
                  memorizzare, gestire e trasmettere i dati, con logiche e
                  modalit&agrave; tali da garantirne la sicurezza e la
                  riservatezza.
                </p>
                <p>
                  BROKER SENZA SORPRESE S.R.L. - SOCIETA' BENEFIT garantisce che
                  i dati trattati saranno sempre pertinenti, completi e non
                  eccedenti rispetto alle finalit&agrave; per le quali sono
                  raccolti, con il Suo impegno di comunicare tempestivamente
                  eventuali correzioni, integrazioni o aggiornamenti; specifiche
                  misure di sicurezza sono osservate per prevenire la perdita
                  dei dati, usi illeciti o non corretti ed accessi non
                  autorizzati.
                </p>
                <p>
                  <strong
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;</strong
                  >
                </p>
                <p>
                  <strong>TEMPI DI CONSERVAZIONE&nbsp; </strong>
                </p>
                <p>
                  I dati personali saranno trattati per il tempo strettamente
                  necessario a conseguire gli scopi, sopra descritti, per
                  adempiere ad obblighi contrattuali, di legge e di regolamento
                  fatti salvi i termini prescrizionali e di legge, nel rispetto
                  dei diritti e in ottemperanza degli obblighi conseguenti.
                </p>
                <p>
                  In particolare, criteri utilizzati per determinare il periodo
                  di conservazione sono stabiliti da specifiche norme di legge
                  (che regolamentano l&rsquo;attivit&agrave; assicurativa), dal
                  periodo di permanenza quale assicurato presso lo scrivente
                  Titolare (soprattutto in relazione alle &ldquo;categorie
                  particolari di dati&rdquo; o di &ldquo;profilazione&rdquo;) e
                  dalla normativa fiscale per quanto riguarda il trattamento dei
                  dati amministrativo-contabili. Con riferimento ai dati
                  raccolti per finalit&agrave; commerciali (di cui al punto 2),
                  i dettagli sono limitati &ndash; per l&rsquo;attivit&agrave;
                  di profilazione - a 1 anno dalla data di acquisizione e 2 anni
                  per l&rsquo;attivit&agrave; di marketing diretto. I dati
                  personali dell&rsquo;assicurato, infine, potranno essere
                  conservati anche fino al tempo permesso dalla legge italiana a
                  tutela degli interessi legittimi della societ&agrave; Titolare
                  (art. 2947, co. 1 e 3 c.c.)&rdquo;.<br />
                  <strong>AMBITO DI CONOSCENZA DEI DATI </strong>
                </p>
                <p>
                  Il trattamento dei dati personali sar&agrave; effettuato, nei
                  limiti delle autorizzazioni di carattere generale rilasciate
                  dal Garante per la protezione dei dati personali, a mezzo di
                  soggetti espressamente e specificamente designati dal
                  Titolare, in qualit&agrave; di responsabili o incaricati. I
                  dati potranno altres&igrave; essere trattati da soggetti terzi
                  (outsourcer), di cui ci si avvale per l&rsquo;erogazione di
                  servizi connessi alla finalit&agrave; perseguita, che la
                  nostra organizzazione valuta di volta in volta, per garantire
                  una maggiore tutela, se nominare responsabili dei trattamenti
                  da questi posti in essere. In tutti i casi, tali soggetti
                  tratteranno i dati conformemente alle istruzioni ricevute dal
                  Titolare, secondo profili operativi agli stessi attribuiti in
                  relazione alle funzioni svolte, limitatamente a quanto
                  necessario e strumentale per l&rsquo;esecuzione di specifiche
                  operazioni nell&rsquo;ambito dei servizi richiesti ed
                  esclusivamente per il conseguimento delle finalit&agrave;
                  indicate nella presente informativa.
                </p>
                <p>
                  L&rsquo;elenco dei Responsabili del trattamento, costantemente
                  aggiornato, pu&ograve; essere richiesto inviando una
                  comunicazione con le modalit&agrave; indicate al successivo
                  punto riguardante i diritti dell&rsquo;interessato
                </p>
                <p>
                  <strong>COMUNICAZIONE E DIFFUSIONE </strong>
                </p>
                <p>
                  I dati potranno essere comunicati (intendendo con tale termine
                  il darne conoscenza ad uno o pi&ugrave; soggetti determinati,
                  diversi dal Titolare, dai responsabili, interni ma anche
                  esterni alla struttura aziendale, e dagli incaricati al
                  trattamento individuati e nominati per il perseguimento delle
                  finalit&agrave; sopra indicate ed in ogni caso nei limiti
                  delle stesse, come segue:
                </p>
                <ul>
                  <li>
                    a soggetti, pubblici e privati, quali Autonomi Titolari, che
                    possono accedere ai dati in forza di disposizione di legge,
                    di regolamento o di normativa comunitaria, nei limiti
                    previsti da tali norme;
                  </li>
                  <li>
                    a soggetti esterni, anche appartenenti al settore
                    assicurativo e finanziario, che svolgono per conto di BROKER
                    SENZA SORPRESE S.R.L. - SOCIETA' BENEFIT attivit&agrave;
                    ausiliarie correlate ai trattamenti dei dati, che la nostra
                    organizzazione valuta di volta in volta, per garantire una
                    maggiore tutela, se nominare responsabili dei trattamenti da
                    questi posti in essere o a cui richiede di sottoscrivere
                    specifiche clausole che impongano il dovere di riservatezza,
                    sicurezza e la garanzia di rispetto del Codice Privacy e
                    successive integrazioni. Tali soggetti possono:
                    <ul>
                      <li>
                        svolgere per nostro conto servizi informatici,
                        telematici, finanziari, amministrativi, di
                        archiviazione, di stampa imbustamento della
                        corrispondenza e di gestione trasporto e smistamento
                        delle comunicazioni alla clientela;
                      </li>
                      <li>
                        operare nella c.d. &ldquo;catena assicurativa&rdquo; con
                        specifici compiti di natura tecnica, organizzativa ed
                        operativa ovvero soggetti appartenenti alla c.d.
                        &ldquo;catena assicurativa&rdquo; (si citano a titolo
                        esemplificativo e non limitativo, imprese di
                        assicurazione, agenti, subagenti, produttori, centri
                        peritali, broker, promotori finanziari, banche, sim)
                        nonch&eacute;
                      </li>
                      <li>
                        avere un ruolo attivo in ragione del rapporto
                        assicurativo con Lei intercorrente (contraenti di
                        polizze in cui si risulti assicurati, beneficiari,
                        coobbligati); o
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        operare in attivit&agrave; di rilevazione circa la
                        qualit&agrave; dei servizi forniti, la soddisfazione
                        della clientela, etc.
                      </li>
                    </ul>
                  </li>
                  <li>
                    a soggetti esterni consulenti di BROKER SENZA SORPRESE
                    S.R.L. - SOCIETA' BENEFIT, nei limiti necessari per svolgere
                    il loro incarico presso la nostra organizzazione, vincolati
                    dalla sottoscrizione di specifiche clausole o accordi che
                    impongano il dovere di riservatezza, sicurezza e la garanzia
                    di rispetto della normativa italiana ed europea in materia
                    di privacy e successive integrazioni;
                  </li>
                  <li>
                    soggetti terzi qualora il trattamento risulti necessario o
                    funzionale agli obblighi di legge e di contratto verso altri
                    soggetti del settore assicurativo con i quali lo scrivente
                    intrattiene o intratterr&agrave; rapporti di reciproca
                    collaborazione ai sensi della legge 221/2012;
                  </li>
                </ul>
                <p>
                  Ove tale comunicazione non fosse possibile senza libero ed
                  esplicito consenso da parte dell&rsquo;interessato,
                  perch&eacute; prevista in relazione alle normative vigenti e
                  nell&rsquo;ambito delle finalit&agrave; dichiarate,
                  l&rsquo;espressione del consenso risulter&agrave;
                  indispensabile per procedere alla comunicazione stessa verso
                  soggetti che svolgono attivit&agrave; ausiliarie rispetto le
                  finalit&agrave; dichiarate.
                </p>
                <p>
                  I dati non verranno diffusi, con tale termine intendendosi il
                  darne conoscenza a soggetti indeterminati in qualunque modo,
                  anche mediante la loro messa a disposizione o consultazione, a
                  meno di specifico consenso, libero ed informato, concesso per
                  ciascun tipo&nbsp;
                </p>
                <p>
                  <strong>DIRITTI DELL&rsquo; INTERESSATO </strong>
                </p>
                <p>
                  I soggetti interessati, cui si riferiscono i dati personali,
                  hanno facolt&agrave; di esercitare in ogni momento i diritti
                  (Diritto di accesso ai dati personali ed altri diritti), in
                  particolare: diritto di ottenere la conferma
                  dell&rsquo;esistenza o meno dei propri dati personali, di
                  accedervi e di conoscerne il contenuto e l&rsquo;origine, di
                  verificarne l&rsquo;esattezza, di chiederne
                  l&rsquo;integrazione o l'aggiornamento, limitazione del
                  trattamento o portabilit&agrave;. La rettificazione e il
                  blocco possono essere richiesti se i dati risultano
                  incompleti, erronei o raccolti in violazione della normativa
                  vigente ed in tal senso &egrave; anche possibile opporsi al
                  loro trattamento per motivi legittimi o a qualsiasi processo
                  decisionale automatizzato (compreso la profilazione),
                  cos&igrave; come &egrave; possibile richiedere, per gli stessi
                  motivi anche la cancellazione purch&eacute; in
                  conformit&agrave; alle norme vigenti e qualora non esistano
                  altri obblighi di conservazione e trattamento in capo a BROKER
                  SENZA SORPRESE S.R.L. - SOCIETA' BENEFIT. I soggetti
                  interessati hanno, altres&igrave;, il diritto di opposizione
                  al trattamento dei propri dati personali per le
                  finalit&agrave; di marketing (indicate al punto 2), anche se
                  effettuato con modalit&agrave; automatizzate di contatto; tale
                  diritto si estende anche a quelle tradizionali e i soggetti
                  interessati potranno esercitare tali diritti in tutto o in
                  parte (es. solo alle comunicazioni tramite sms, e-mail o
                  telefono ovvero opponendosi al solo invio di comunicazioni
                  promozionali effettuato tramite strumenti automatizzati,
                  etc.).
                </p>
                <p>
                  In merito all&rsquo;esercizio dei propri diritti sopra
                  elencati, cos&igrave; come per conoscere l&rsquo;elenco
                  aggiornato dei responsabili del trattamento dei dati
                  personali, il soggetto interessato potr&agrave; rivolgere le
                  proprie richieste attraverso specifica comunicazione a mezzo
                  posta indirizzata alla medesima Societ&agrave;, o attraverso
                  la casella di posta elettronica: info@brokersenzasorprese.com,
                  anche inviando comunicazione all&rsquo;indirizzo e-mail
                  indicato, al fine di ottenere tempestivo riscontro.
                </p>
                <p>
                  Qualora il soggetto interessato abbia prestato il consenso al
                  trattamento per una specifica finalit&agrave;, lo stesso ha
                  sempre il diritto di revocarlo in qualsiasi momento senza
                  pregiudicare la liceit&agrave; del trattamento basata sul
                  consenso prestato prima della revoca.&nbsp;
                </p>
                <p>
                  Si ricorda inoltre che il soggetto interessato ha sempre il
                  diritto di proporre un reclamo all'Autorit&agrave; Garante per
                  la protezione dei dati personali per l&rsquo;esercizio dei
                  suoi diritti o per qualsiasi altra questione relativa al
                  trattamento dei suoi dati personali.
                  <strong>TITOLARE DEL TRATTAMENTO </strong>
                </p>
                <p>
                  Il Titolare del trattamento dei dati personali &egrave; BROKER
                  SENZA SORPRESE S.R.L. - SOCIETA' BENEFIT con sede in Via
                  Montegrappa, 37/C &ndash; 36063 Marostica (VI). Il Titolare
                  conserva una lista aggiornata dei responsabili nominati, e ne
                  garantisce la presa visione all&rsquo;interessato presso la
                  sede sopra indicata.
                </p>
              </div>
              <p>
                <strong>CONSENSO AL TRATTAMENTO DEI DATI PERSONALI</strong>
              </p>
              <form
                role="form"
                @submit.prevent="onSubmit"
                v-for="privacySingola in privacybss"
                :key="privacySingola.idrssprivacy"
              >
                <div class="row mb-3">
                  <div class="col-12 align-items-left justify-content">
                    <span>{{ privacySingola.domanda }}</span>
                  </div>
                  <div
                    class="col-12 form-group align-items-center justify-content"
                    style="text-align: center; vertical-align: middle"
                  >
                    <span class="mb-2 mt-2">Acconsento:</span>
                    <br />
                    <base-switch
                      :value="checkPrivacyValue(privacySingola)"
                      v-if="privacySingola.can_change"
                      type="primary"
                      offText="No"
                      onText="Si"
                      @input="
                        changePrivacy($event, privacySingola.idrssprivacy)
                      "
                    ></base-switch>
                    <label
                      class="custom-toggle custom-toggle-danger"
                      v-if="!privacySingola.can_change"
                    >
                      <input
                        type="checkbox"
                        :checked="checkPrivacyValue(privacySingola)"
                        disabled
                      />
                      <span
                        data-label-off="No"
                        data-label-on="Si"
                        class="custom-toggle-slider rounded-circle"
                      >
                      </span>
                    </label>
                    <base-alert type="danger" v-if="!privacySingola.can_change">
                      <strong
                        >Il consenso è obbligatorio e non può essere
                        modificato</strong
                      >
                    </base-alert>
                  </div>
                </div>
                <hr />
              </form>
            </div>
          </section>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import "flatpickr/dist/flatpickr.css";
import apiCall from "@/utils/api";
import Loading from "vue-loading-overlay";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { formatDate } from "@/filters/formatDate";
import BaseAlert from "@/components/BaseAlert";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";
export default {
  name: "privacy-bss",
  components: {
    Loading,
    BaseAlert,
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(false);

    const showInformativa = ref(false);
    const privacybss = ref({});
    const rssPolizzaPrivacy = ref({});

    const onSubmit = function () {
      // store.dispatch("auth/login", values);
      // formError.value = false;
    };

    onMounted(async () => {
      isLoading.value = true;
      let resp = await apiCall({
        url: "/private/privacy/bss",
        data: {},
        method: "GET",
      });

      privacybss.value = resp.privacy;

      rssPolizzaPrivacy.value = resp.userprivacy;
      isLoading.value = false;
    });

    const goToHome = function () {
      router.push("/");
    };

    const checkPrivacyValue = function (privacySingola) {
      const element = rssPolizzaPrivacy.value.find(
        (element) => element.idrssprivacy == privacySingola.idrssprivacy
      );
      if (element !== undefined) {
        return element.risposta;
      } else {
        return privacySingola.default_response;
      }
    };

    const changePrivacy = async function ($event, key) {
      if (typeof $event === "boolean") {
        const element = rssPolizzaPrivacy.value.find(
          (element) => element.idrssprivacy == key
        );
        isLoading.value = true;
        await apiCall({
          url: "/private/setPrivacy",
          data: {
            valore: $event,
            idrssprivacy: key,
            idrsspolizza: element.idrsspolizza,
            idrsspolizzaprivacy: element.idrsspolizzaprivacy,
          },
          method: "POST",
        });
        isLoading.value = false;
      }
    };

    return {
      rssPolizzaPrivacy,
      checkPrivacyValue,
      changePrivacy,
      showInformativa,
      isLoading,
      privacybss,
      goToHome,
      formatDate,
      onSubmit,
    };
  },
};
</script>
<style>
.account-settings .nav {
  text-align: left;
}

.account-settings .nav .nav-item {
  padding: 1rem 0;
}

.account-settings .nav .nav-item:not(:last-child) {
  border-bottom: 1px solid #5e72e4;
}

/* Checkmark & Strikethrough --------- */

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 100%;
}

.checkmark_container {
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: -8px;
  background: #2ecc71;
  width: 25px;
  height: 25px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.is_valid {
  color: green;
}

.is_invalid {
  color: red;
}

.checked {
  animation: draw 0.5s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
