<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">
                Metodo pagamento<br />{{ policy_number }}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div class="col-md-12 pl-md-0">
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>
          <br />
          <div class="table-responsive">
            <table class="table align-items-center">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Conto Corrente</th>
                  <th scope="col">Attivo</th>
                  <th scope="col">Operazioni</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="pagamento in pagamenti"
                  :key="pagamento.idrsspolizzapagam"
                >
                  <th scope="row">
                    <i class="fa fa-bank fa-3x"></i>
                  </th>
                  <td>
                    <span class="d-block"
                      >•••• •••• •••• {{ last4Character(pagamento.iban) }}</span
                    >
                    <small class="text-muted"
                      >Data mandato:
                      {{ formatDate(pagamento.mandate_signature_date) }}</small
                    >
                  </td>
                  <td class="text-center">
                    <i
                      class="fa fa-check fa-2x"
                      style="color: green"
                      v-if="pagamento.active"
                      aria-hidden="true"
                    ></i>
                    <base-button
                      type="success"
                      simple
                      size="sm"
                      v-if="!pagamento.active"
                      @click="activateIBAN(pagamento.idrsspolizzapagam)"
                    >
                      ATTIVA
                    </base-button>
                  </td>
                  <td>
                    <base-button
                      type="danger"
                      simple
                      size="sm"
                      v-if="!pagamento.active"
                      @click="deleteIBAN(pagamento.idrsspolizzapagam)"
                    >
                      Elimina
                    </base-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <base-button
              type="primary"
              size="sm"
              @click="showModalBanca = true"
            >
              Aggiungi
            </base-button>
          </div>
        </card>
      </div>
    </div>
    <modal
      :show="showModalBanca"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-lg"
    >
      <card
        type="secondary"
        shadow
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <div class="text-center text-muted mb-4">AGGIUNGI NUOVO IBAN</div>
        <base-alert type="danger" v-if="!isValidIBAN">
          <strong>IBAN non valido</strong>
        </base-alert>
        <form role="form">
          <base-input
            alternative
            class="mb-3"
            name="iban"
            placeholder="IBAN"
            @input="validateIBAN"
            addon-left-icon="fa fa-bank"
          >
          </base-input>
          <div class="text-center">
            <base-button
              :disabled="!isValidIBAN && iban == ''"
              type="primary"
              class="my-4"
              @click="saveIBAN()"
              >SALVA</base-button
            >
          </div>
        </form>
      </card>
    </modal>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { formatDate, last4Character } from "@/filters/formatDate";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import BaseAlert from "@/components/BaseAlert";
import Modal from "@/components/Modal";

export default {
  name: "pagamento-page",
  components: {
    Loading,
    BaseAlert,
    Modal,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(false);

    const pagamenti = ref(null);
    const isValidIBAN = ref(false);
    const showModalBanca = ref(false);
    const policy_number = ref(null);

    const iban = ref("");
    const polizza = ref("");
    //const iban = useField("iban");

    onMounted(async () => {
      isLoading.value = true;
      policy_number.value = route.params.id;
      let resp = await apiCall({
        url: "/private/getIBAN/" + policy_number.value,
        data: {},
        method: "GET",
      });
      polizza.value = resp.polizza;
      pagamenti.value = resp.iban;
      isLoading.value = false;
    });

    const saveIBAN = async function () {
      /*  await this.$store.dispatch("user/saveIBAN", {
        valore: this.iban,
        idrsspolizza: this.polizza.idrsspolizza
      });*/
      let resp = await apiCall({
        url: "/private/setIBAN/" + policy_number.value,
        data: {
          valore: iban.value,
          idrsspolizza: polizza.value.idrsspolizza,
        },
        method: "POST",
      });
      pagamenti.value = resp;
      iban.value = "";
      isValidIBAN.value = false;
      showModalBanca.value = false;
    };

    const activateIBAN = async function (idrsspolpagam) {
      let resp = await apiCall({
        url: "/private/activateIBAN/" + policy_number.value,
        data: {
          idrsspolizzapagam: idrsspolpagam,
          idrsspolizza: polizza.value.idrsspolizza,
        },
        method: "POST",
      });
      pagamenti.value = resp;
    };

    const deleteIBAN = async function (idrsspolpagam) {
      let resp = await apiCall({
        url: "/private/deleteIBAN/" + policy_number.value,
        data: {
          idrsspolizzapagam: idrsspolpagam,
          idrsspolizza: polizza.value.idrsspolizza,
        },
        method: "POST",
      });
      pagamenti.value = resp;
    };

    const validateIBAN = async function ($event) {
      const IBAN = require("iban");
      isValidIBAN.value = IBAN.isValid($event.target.value); // false
      iban.value = $event.target.value;
    };

    const goToHome = function () {
      router.push("/");
    };

    return {
      pagamenti,
      isValidIBAN,
      showModalBanca,
      iban,
      policy_number,
      isLoading,
      saveIBAN,
      deleteIBAN,
      activateIBAN,
      goToHome,
      last4Character,
      formatDate,
      validateIBAN,
    };
  },
};
</script>
<style></style>
