<template>
  <div>
    <loading :active="isLoading" :is-full-page="true"></loading>
    <!-- Header -->
    <div class="header sfondoImg py-7 py-lg-8 pt-lg-9">
      <div class="container opacityback">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="display-2 text-white text-uppercase">
                documenti contrattuali<br />{{ policy_number }}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="row mt--8 pb-5 ml-2 mr-2">
      <div class="col-md-12 pl-md-0">
        <card
          gradient="secondary"
          class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
          :style="{ minHeight: '450px' }"
          header-classes="bg-transparent"
          body-classes="px-lg-7"
          footer-classes="bg-transparent"
        >
          <div class="text-center">
            <base-button
              @click="goToHome()"
              type="primary"
              size="xl"
              class="mb-5"
              >Torna alla Home</base-button
            >
          </div>
          <br />
          <div class="table-responsive">
            <table class="table text-left">
              <thead class="thead-light">
                <tr>
                  <th scope="col">Documento</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="documento in documents"
                  :key="documento.idrsspolizzadocs"
                >
                  <th scope="row">
                    {{ documento.description }}
                  </th>
                  <td scope="row">
                    <base-button
                      class="btn-block"
                      size="sm"
                      type="success"
                      @click="downloadFile(documento)"
                      >Scarica</base-button
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { saveAs } from "file-saver";
import apiCall from "@/utils/api";
import { ref, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { runToast } from "@/util/notif";
export default {
  name: "documenti-page",
  components: {
    Loading,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(false);
    const policy_number = ref(null);
    const documents = ref(null);

    // fetch the user information when params change
    watch(
      () => route.params.id,
      async (id) => {
        policy_number.value = id;
        await loadData();
      }
    );

    onMounted(async () => {
      policy_number.value = route.params.id;
      await loadData();
    });

    const getFileFromBase64 = function (string64, fileName) {
      const trimmedString = string64.replace("dataimage/jpegbase64", "");
      const imageContent = atob(trimmedString);
      const buffer = new ArrayBuffer(imageContent.length);
      const view = new Uint8Array(buffer);

      for (let n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n);
      }
      const type = "application/pdf;charset=utf-8";
      const blob = new Blob([buffer], { type });
      return new File([blob], fileName, {
        lastModified: new Date().getTime(),
        type,
      });
    };

    const loadData = async function () {
      if (policy_number.value) {
        isLoading.value = true;
        let resp = await apiCall({
          url: "/private/getDocuments/" + policy_number.value,
          data: {},
          method: "GET",
        });

        documents.value = resp;
        isLoading.value = false;
      }
    };

    const downloadFile = async function (documento) {
      try {
        let respDoc = await apiCall({
          url: "/private/polizza/getDocument",
          data: documento,
          method: "POST",
        });
        const strFileName = documento.description + ".pdf";
        const fileEncoded = this.getFileFromBase64(respDoc.data, strFileName);
        saveAs(fileEncoded, strFileName);
      } catch (err) {
        runToast("Errore download file", "top-center", "danger");
      }
    };

    const goToHome = function () {
      router.push("/");
    };

    return {
      documents,
      isLoading,
      policy_number,
      getFileFromBase64,
      downloadFile,
      goToHome,
    };
  },
};
</script>
<style></style>
